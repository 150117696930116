import {
  ArrowBackIosIcon,
  Box,
  Button,
  Tab,
  Tabs,
  enerbitColors,
} from "@enerbit/base";
import { SyntheticEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StateStorage } from "../../models/StateStorage";
import { TabPanelProps } from "../../models/props/TabPanel";
import { TabModel } from "../../models/ui/Tab";
import { setIndexTab } from "../../store/slices/informationSlice";
import { AppDispatch } from "../../store/store";

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export type MuiTabsProps = {
  tabs: TabModel[];
  disabled?: boolean;
};

export default function MuiTabs({ tabs, disabled }: MuiTabsProps) {
  const dispatch = useDispatch<AppDispatch>();
  const { indexTab } = useSelector((state: StateStorage) => state.information);
  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    dispatch(setIndexTab(newValue));
  };
  const navigate = useNavigate();
  return (
    <Box sx={{ width: "100%", display: "flex" }}>
      <Box sx={{ width: "7%" }}>
        <Button
          onClick={() => navigate(-1)}
          sx={{
            display: "flex",
            padding: "0.6rem 0.8rem !important",
            justifyItems: "center",
            height: "2.7rem",
            justifyContent: "center",
            minWidth: "0 !important",
            borderRadius: "8px !important",
            background: enerbitColors.primary[100],
          }}
        >
          <ArrowBackIosIcon
            sx={{ color: enerbitColors.neutral[700], fontSize: "17px" }}
          />
        </Button>
      </Box>
      <Box sx={{ width: "93%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: "20px" }}>
          <Tabs
            value={indexTab}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{
              style: { backgroundColor: "orange", color: "black" },
            }}
          >
            {tabs.map(({ label, disabled }, i) => (
              <Tab
                disabled={disabled}
                className="Custom-tabs"
                label={label}
                key={i}
              />
            ))}
          </Tabs>
        </Box>
        {tabs.map(({ component }, i) => (
          <TabPanel value={indexTab} index={i} key={i}>
            {component}
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
}
