import { Chip, Stack, Tooltip } from "@mui/material";

type BodyComponentProps = {
  color?: string;
  data?: { id: string; name: string; created_at: string; updated_at: string }[];
};

const BodyComponent = ({ color, data }: BodyComponentProps) => {
  return (
    <Stack
      spacing={1}
      alignItems="left"
      direction="row"
      flexWrap="wrap"
      justifyContent="flex-start"
      style={{ width: "100%" }}
    >
      {data?.map((item, index) => (
        <Tooltip key={index} title={item.name}>
          <Chip
            key={index}
            label={item.name}
            style={{
              margin: "0.2rem 1.5rem 0.8rem 0",
              height: "2.313rem",
              backgroundColor: "#E0F2FF",
              fontSize: "0.875rem",
              color: color,
              borderRadius: "8px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              flexBasis: "auto",
              flexGrow: 0,
            }}
          />
        </Tooltip>
      ))}
    </Stack>
  );
};

export default BodyComponent;
