import { initDownload } from "@enerbit/base";
import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import moment, { Moment } from "moment";

import { MomentHelpers } from "../../helpers/moment/MomentHelpers";
import { setPeriodComparison } from "../../helpers/usages/utilsPeriodComparison";
import { UsagesState } from "../../models/states/UsagesState";
import { OnChangeSelectUsagesTableProps } from "../../models/usages/OnChangeSelectUsagesTableProps";
import { Period } from "../../models/usages/Period";
import { PickerDateRange } from "../../models/usages/PickerDateRange";
import { Usage } from "../../models/usages/Usage";
import { getReactiveImp50ActiveImp } from "../../utils/usagesUtils";
import {
  getUsages,
  getUsagesForDownloadByCsv,
} from "../actions/usages/usages.actions";

// TODO: Mover a models

const initialState: UsagesState = {
  selectUsagesTable: "Mes",
  dateRangeTable: {
    since: MomentHelpers.convertToFirstInstantOfDay(
      MomentHelpers.convertToMomentAmericaBogota(Date.now()).subtract(
        6,
        "month"
      )
    ),
    until: MomentHelpers.convertToMomentAmericaBogota(Date.now()),
  },
  selectPickerDownload: "Mes",
  sincePickerDownload: MomentHelpers.convertToFirstInstantOfDay(
    MomentHelpers.convertToMomentAmericaBogota(Date.now())
      .subtract(1, "month")
      .startOf("month")
  ),
  untilPickerDownload: MomentHelpers.convertToFirstInstantOfDay(
    MomentHelpers.convertToMomentAmericaBogota(Date.now()).startOf("month")
  ),

  usages: [],
  isLoadingUsages: false,
  usagesForDownloadByCsv: [],
  isLoadingUsagesForDownloadByCsv: false,
  usagesForDownloadMessageByCsv: "",
  loadingUsagesMessage: "",
  selectPeriodComparison: "Mes",
  dateRangeComparison: {
    since: MomentHelpers.convertToFirstInstantOfDay(
      MomentHelpers.convertToMomentAmericaBogota(Date.now())
        .subtract(2, "month")
        .startOf("month")
    ),
    until: MomentHelpers.convertToLastInstantOfDay(
      MomentHelpers.convertToMomentAmericaBogota(Date.now())
        .subtract(1, "month")
        .startOf("month")
    ),
  },
  typeChangePlan: "alreadyHasAPlan",
};

export const usagesSlice = createSlice({
  name: "usages",
  initialState,
  reducers: {
    //Funcion del Select para seleccionar filtro por "Mes", "Día", "Hora" de tabla de consumos
    onChangeSelectUsagesTable: (
      state,
      action: PayloadAction<OnChangeSelectUsagesTableProps>
    ) => {
      const { period, minDate } = action.payload;
      state.selectUsagesTable = period;
      switch (period) {
        case "Mes":
          if (
            minDate?.isBefore(
              MomentHelpers.convertToMomentAmericaBogota(Date.now()).subtract(
                6,
                "month"
              )
            )
          ) {
            state.dateRangeTable = {
              since: MomentHelpers.convertToFirstInstantOfDay(
                MomentHelpers.convertToMomentAmericaBogota(Date.now()).subtract(
                  6,
                  "month"
                )
              ),
              until: MomentHelpers.convertToMomentAmericaBogota(Date.now()),
            };
          } else {
            state.dateRangeTable = {
              since: MomentHelpers.convertToFirstInstantOfDay(
                MomentHelpers.convertToMomentAmericaBogota(minDate)
              ),
              until: MomentHelpers.convertToMomentAmericaBogota(Date.now()),
            };
          }
          break;
        case "Día":
          if (
            minDate?.isBefore(
              MomentHelpers.convertToMomentAmericaBogota(Date.now()).subtract(
                28,
                "day"
              )
            )
          ) {
            const since = MomentHelpers.convertToFirstInstantOfDay(
              MomentHelpers.convertToMomentAmericaBogota(Date.now())
            ).subtract(28, "day");

            state.dateRangeTable = {
              since,
              until: MomentHelpers.convertToMomentAmericaBogota(Date.now()),
            };
          } else {
            state.dateRangeTable = {
              since: MomentHelpers.convertToFirstInstantOfDay(
                MomentHelpers.convertToMomentAmericaBogota(minDate)
              ),
              until: MomentHelpers.convertToMomentAmericaBogota(Date.now()),
            };
          }
          break;
        case "Hora":
          state.dateRangeTable = {
            since: MomentHelpers.convertToFirstInstantOfDay(
              MomentHelpers.convertToMomentAmericaBogota(Date.now())
            ),
            until: MomentHelpers.convertToMomentAmericaBogota(Date.now()),
          };
      }
    },
    //Función para cambiar el since de la tabla de consumos
    onChangeSinceUsagesTable: (state, action: PayloadAction<Moment>) => {
      const period = state.selectPeriodComparison;
      const { selectUsagesTable } = state;
      switch (selectUsagesTable) {
        case "Hora":
        case "Día":
          state.dateRangeTable = {
            ...state.dateRangeTable,
            since: action.payload,
          };
          break;
        case "Mes":
          state.dateRangeTable = {
            ...state.dateRangeTable,
            since: setPeriodComparison(period, action.payload),
          };
          break;
      }
    },
    //Función para cambiar el until de la tabla de consumos
    onChangeUntilUsagesTable: (state, action: PayloadAction<Moment>) => {
      state.dateRangeTable = {
        ...state.dateRangeTable,
        until: action.payload,
      };
    },
    //Función para cambiar el since de la descarga de consumos
    onChangeSincePickerDownload: (state, action: PayloadAction<Moment>) => {
      state.sincePickerDownload = MomentHelpers.convertToFirstInstantOfDay(
        MomentHelpers.convertToMomentAmericaBogota(action.payload).startOf(
          "month"
        )
      );
    },
    //Función para cambiar el until de la descarga de consumos
    onChangeUntilPickerDownload: (state, action: PayloadAction<Moment>) => {
      state.untilPickerDownload = MomentHelpers.convertToMomentAmericaBogota(
        action.payload
      );
    },
    //Función para limpiar el mensaje de error de la descarga de consumos
    cleanUsagesForDownloadMessage: (state) => {
      state.usagesForDownloadMessageByCsv = "";
    },
    //Función del Select para seleccionar filtro por "Mes", "Semana", "Día" de comparación de consumos por periodo
    onChangeSelectPeriodComparison: (state, action: PayloadAction<Period>) => {
      state.dateRangeComparison = {} as PickerDateRange;
      state.selectPeriodComparison = action.payload;
      const period = state.selectPeriodComparison;

      switch (period) {
        case "Mes":
          state.dateRangeComparison = {
            since: MomentHelpers.convertToFirstInstantOfDay(
              MomentHelpers.convertToMomentAmericaBogota(Date.now())
                .subtract(2, "month")
                .startOf("month")
            ),
            until: MomentHelpers.convertToLastInstantOfDay(
              MomentHelpers.convertToMomentAmericaBogota(Date.now())
                .subtract(1, "month")
                .startOf("month")
            ),
          };
          break;
        case "Día":
          state.dateRangeComparison = {
            since: MomentHelpers.convertToFirstInstantOfDay(moment())
              .subtract(2, "day")
              .startOf("day"),
            until: MomentHelpers.convertToLastInstantOfDay(moment())
              .subtract(1, "day")
              .startOf("day"),
          };
          break;
        case "Semana":
          state.dateRangeComparison = {
            since: MomentHelpers.convertToFirstInstantOfDay(moment())
              .subtract(2, "week")
              .startOf("week"),
            until: MomentHelpers.convertToLastInstantOfDay(moment())
              .subtract(1, "week")
              .startOf("week"),
          };
      }
    },
    //Función para cambiar el since de la comparación de consumos por periodo
    onChangeSincePeriodComparison: (state, action: PayloadAction<Moment>) => {
      const period = state.selectPeriodComparison;

      state.dateRangeComparison = {
        ...state.dateRangeComparison,
        since: setPeriodComparison(period, action.payload),
      };
    },
    //Función para cambiar el until de la comparación de consumos por periodo
    onChangeUntilPeriodComparison: (state, action: PayloadAction<Moment>) => {
      const period = state.selectPeriodComparison;
      state.dateRangeComparison = {
        ...state.dateRangeComparison,
        until: setPeriodComparison(period, action.payload),
      };
    },
    onCleanUsagesForDownload: (state) => {
      state.usagesForDownloadByCsv = [];
    },
    setValueTypeChangePlan: (
      state,
      action: PayloadAction<"alreadyHasAPlan" | "createService" | "assignPlan">
    ) => {
      state.typeChangePlan = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<UsagesState>) => {
    builder
      .addCase(getUsages.pending, (state) => {
        state.usages = [];
        state.isLoadingUsages = true;
      })
      .addCase(getUsages.fulfilled, (state, action: PayloadAction<Usage[]>) => {
        state.isLoadingUsages = false;
        state.usages = [];
        state.usages = action.payload;
      })
      .addCase(getUsages.rejected, (state) => {
        state.isLoadingUsages = false;
      })
      .addCase(getUsagesForDownloadByCsv.pending, (state) => {
        state.usagesForDownloadByCsv = [];
        state.isLoadingUsagesForDownloadByCsv = true;
        state.usagesForDownloadMessageByCsv = "";
        initDownload(true);
      })
      .addCase(
        getUsagesForDownloadByCsv.fulfilled,
        (state, action: PayloadAction<Usage[]>) => {
          state.usagesForDownloadByCsv = action.payload.map((value) => ({
            ...value,
            reactive_50_active: getReactiveImp50ActiveImp(
              value.reactive_energy_imported || 0,
              value.active_energy_imported || 0
            ),
          }));
          state.isLoadingUsagesForDownloadByCsv = false;
          if (action.payload.length === 0) {
            state.usagesForDownloadMessageByCsv =
              "Para el periodo seleccionado, no hay datos disponibles";
            return;
          } else {
            state.usagesForDownloadMessageByCsv =
              "La descarga ha comenzado. Revisa tu carpeta de descargas";
          }
        }
      )
      .addCase(getUsagesForDownloadByCsv.rejected, (state) => {
        state.isLoadingUsagesForDownloadByCsv = false;
        state.usagesForDownloadMessageByCsv =
          "Algo ocurrió. Inténtalo de nuevo más tarde o contacta a soporte";
        initDownload(false);
      });
  },
});

export const {
  onChangeSelectUsagesTable,
  onChangeSinceUsagesTable,
  onChangeUntilUsagesTable,
  onChangeSincePickerDownload,
  onChangeUntilPickerDownload,
  cleanUsagesForDownloadMessage,
  onChangeSelectPeriodComparison,
  onChangeSincePeriodComparison,
  onChangeUntilPeriodComparison,
  onCleanUsagesForDownload,
  setValueTypeChangePlan,
} = usagesSlice.actions;

export default usagesSlice.reducer;
