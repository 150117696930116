import moment, { Moment } from "moment";
import { Period } from "../../models/usages/Period";

//Funcion para el onChange del SincePeriodComparison y UntilPeriodComparison del Slice de usages
export const setPeriodComparison = (
  period: Period,
  action_payload: Moment
): Moment => {
  switch (period) {
    case "Mes":
      return action_payload.startOf("month");
    case "Día":
      return action_payload.startOf("day");
    case "Semana":
      return action_payload !== action_payload.startOf("week")
        ? action_payload.startOf("week")
        : action_payload;
    default:
      return action_payload;
  }
};

export const setSinceComparisonMinDate = (
  period: Period,
  date: Moment,
  started_at: Moment
): Moment | undefined => {
  switch (period) {
    // Si la fecha seleccionada es menor al started_at, la fecha minima sería el started_at si el started_at  comienza el 1 del mes, si no, sería el 1 del mes posterior.
    case "Mes":
      if (date.isBefore(started_at) && started_at.date() === 1) {
        return started_at;
      } else {
        return moment(started_at).add(1, "month").startOf("month");
      }
    // Si la fecha seleccionada es menor al started_at, la fecha minima sería el started_at y esta comienza el domingo, si no, sería la semana siguiente.
    case "Semana":
      if (date.isBefore(started_at) && started_at.day() === 0) {
        return started_at;
      } else {
        return moment(started_at).add(1, "week").startOf("week");
      }
    case "Día":
      //Si la fecha seleccionada es menor al started_at, la fecha minima sería el started_at
      if (date.isBefore(started_at)) {
        return started_at;
      }
      return undefined;
    //TODO: IMPLEMENTAR CASOS PENDIENTES AL SWITCH
    default:
      return undefined;
  }
};

export const setUntilComparisonMinDate = (period: Period, since: Moment) => {
  switch (period) {
    //Mes siguiente al since
    case "Mes":
      return moment(since).add(1, "month").startOf("month");
    // La siguiente semana al since
    case "Semana":
      return moment(since).add(1, "week").startOf("week");
    //día posterior al since
    case "Día":
      return moment(since).add(1, "day").startOf("day");
    default:
      return since;
  }
};

export const setSinceComparisonMaxDate = (period: Period, date: Moment) => {
  switch (period) {
    //Mes trasanterior
    case "Mes":
      return moment(date).subtract(2, "month").endOf("month");
    //Semana trasanterior
    case "Semana":
      return moment(date).subtract(2, "week").endOf("week");
    //Día trasanterior
    case "Día":
      return moment(date).subtract(2, "day").endOf("day");
    default:
      return date;
  }
};

export const setUntilComparisonMaxDate = (period: Period) => {
  switch (period) {
    //Mes Anterior
    case "Mes":
      return moment().subtract(1, "month").startOf("month");
    //Semana anterior
    case "Semana":
      return moment().subtract(1, "week").startOf("week");
    //Día anterior
    case "Día":
      return moment().subtract(1, "day").startOf("day");
    //TODO: IMPLEMENTAR CASOS PENDIENTES AL SWITCH
    default:
      return undefined;
  }
};
