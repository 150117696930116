import { createAsyncThunk } from "@reduxjs/toolkit";
import { IBilling, ITotalOwe } from "../../../models/interfaces/IBilling";
import { api } from "@enerbit/base";
import { AxiosInstance } from "axios";

export const getInvoices = createAsyncThunk(
    //action type string
    "invoices/getInvoices",
    //callback function
    async (
        {
            ess_id,
            page,
            month_filter,
        }: { ess_id: string; page: number; month_filter: string },

        thunkAPI,
    ): Promise<IBilling[] | any> => {
        try {
            const { data } = await (api as AxiosInstance).get(
                `/electricity-supply-service/billing/billing/${ess_id}`,
                {
                    params: {
                        page: page,
                        month_filter: month_filter,
                    },
                },
            );
            return data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response);
        }
    },
);

export const getTotalOwed = createAsyncThunk(
    //action type string
    "invoices/getTotalOwed",
    //callback function
    async (
        { ess_id, month_filter }: { ess_id: string; month_filter: string },
        thunkAPI,
    ): Promise<ITotalOwe | any> => {
        try {
            const { data } = await (api as AxiosInstance).get(
                `/electricity-supply-service/billing/billing/total_owe/${ess_id}`,
                {
                    params: {
                        month_filter: month_filter,
                    },
                },
            );
            return data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response);
        }
    },
);

export const downloadInvoiceGroup = createAsyncThunk(
    "invoices/downloadInvoiceGroup",
    async (invoiceIds: string[], _thunkAPI: any) => {
        let path = "/billing/invoice-documents-merge/";
        for (var i = 0; i < invoiceIds.length; i++) {
            if (i == 0) {
                path += "?invoices_ids=" + invoiceIds[i];
            } else {
                path += "&invoices_ids=" + invoiceIds[i];
            }
        }
        let response = await api.get(path, {
            responseType: "arraybuffer",
        });
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = `${new Date().getTime()}.pdf`;
        link.click();
        URL.revokeObjectURL(fileURL);
    },
);
