import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AdapterDayjs,
  Box,
  CustomAlert,
  DownloadOutlinedIcon,
  enerbitColors,
  ExpandMoreIcon,
  LoadingButton,
  LocalizationProvider,
  moment,
  Stack,
  styled,
  Typography,
} from "@enerbit/base";
import { Moment } from "moment";
import { useEffect, useRef } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import CustomDatePicker from "../../../../common/custom-date-picker/CustomDatePicker";
import { MomentHelpers } from "../../../../helpers/moment/MomentHelpers";
import { PayloadGetUsages } from "../../../../models/payloads/consumption/PayloadGetUsages";
import { StateStorage } from "../../../../models/StateStorage";
import { getUsagesForDownloadByCsv } from "../../../../store/actions/usages/usages.actions";
import {
  cleanUsagesForDownloadMessage,
  onChangeSincePickerDownload,
  onChangeUntilPickerDownload,
  onCleanUsagesForDownload,
} from "../../../../store/slices/usagesSlice";
import { AppDispatch } from "../../../../store/store";
//Componente correspondiente a la sección de descarga de consumos
const Download = () => {
  const {
    sincePickerDownload,
    untilPickerDownload,
    usagesForDownloadByCsv,
    isLoadingUsagesForDownloadByCsv,
    usagesForDownloadMessageByCsv,
  } = useSelector((state: StateStorage) => state.usages);
  const dispatch = useDispatch<AppDispatch>();

  //Obtener información del servicio de electricidad
  const { service } = useSelector((state: StateStorage) => state.information);

  const usageParamsObjectForDownloadByCsv: PayloadGetUsages = {
    electricity_supply_service_id: service?.id ?? "",
    since: sincePickerDownload,
    until: untilPickerDownload,
    period_str: "hour",
  };

  const headers = [
    { label: "Desde", key: "start" },
    { label: "Hasta", key: "end" },
    { label: "E. activa importada (Wh)", key: "active_energy_imported" },
    { label: "E. reactiva importada (VArh)", key: "reactive_energy_imported" },
    { label: "E. activa exportada (Wh)", key: "active_energy_exported" },
    { label: "E. reactiva exportada (VArh)", key: "reactive_energy_exported" },
    {
      label: "Reactiva imp que supera 50% de activa imp",
      key: "reactive_50_active",
    },
  ];

  const csvReport = {
    //Nombre del archivo a descargar con mes
    filename: `${moment(sincePickerDownload).format(
      MomentHelpers.YYYYMM
    )}_reporte_consumo.csv`,
    headers: headers,
    data: usagesForDownloadByCsv,
  };

  //Referencia para dar click al botón escondido de descarga
  const buttonRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  //Create request for download csv
  const downloadUsagesForByCsv = async () => {
    dispatch(getUsagesForDownloadByCsv(usageParamsObjectForDownloadByCsv));
  };

  //Sólo si encontro usages para descargar, se hace click en el botón escondido para descargar el csv
  //Evitar que se de click cada que se cambie el tab

  useEffect(() => {
    if (usagesForDownloadByCsv && usagesForDownloadByCsv.length > 0) {
      buttonRef.current?.link?.click();
      dispatch(onCleanUsagesForDownload());
    }
  }, [usagesForDownloadByCsv]);

  useEffect(() => {
    //Si sincePickerDownload es igual al mes actual, se setea el valor de untilPickerDownload en el día actual hasta las 23:59:59
    if (moment(sincePickerDownload).isSame(moment(), "month")) {
      dispatch(onChangeUntilPickerDownload(moment(Date.now())));
    } else {
      //Si sincePickerDownload no es igual al mes actual, se setea el valor de untilPickerDownload en el último día del mes
      dispatch(
        onChangeUntilPickerDownload(moment(sincePickerDownload).endOf("month"))
      );
    }
  }, [sincePickerDownload]);

  return (
    <>
      {service?.meter?.id && (
        <Box sx={{ my: "36px", pb: "36px" }}>
          <CustomizedAccordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ backgroundColor: enerbitColors.neutral[100] }}
            >
              <Box display="flex" flexDirection="column" sx={{ pb: "24px" }}>
                <Typography sx={{ fontWeight: 700, fontSize: "18px" }}>
                  Descarga
                </Typography>
                <Typography sx={{ color: enerbitColors.neutral.main }}>
                  Importe datos del perfil de consumo en formato de archivo csv,
                  que luego se puede leer usando un programa de hoja de cálculo
                  como excel. Puede descargar hasta dos años de datos.
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{ fontSize: "14px", fontWeight: "700", mb: "8px" }}
              >
                Selecciona el mes y año a descargar
              </Typography>
              <Box display="flex" flexDirection="column">
                <Box display="flex" gap="1rem" alignItems="center">
                  <Box sx={{ width: "50%" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={3} sx={{ width: "100%" }}>
                        <CustomDatePicker
                          value={sincePickerDownload}
                          onChange={(newValue: Moment | null) => {
                            if (newValue) {
                              dispatch(onChangeSincePickerDownload(newValue));
                              dispatch(onChangeUntilPickerDownload(newValue));
                            }
                          }}
                          icon="calendar"
                          isLabel={false}
                          typePicker="Mes"
                          maxDate={moment()}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Box>

                  <Box sx={{ width: "50%" }}>
                    <CSVLink
                      {...csvReport}
                      className="csv"
                      ref={buttonRef}
                    ></CSVLink>
                    <LoadingButton
                      variant="contained"
                      color="secondary"
                      startIcon={<DownloadOutlinedIcon />}
                      onClick={downloadUsagesForByCsv}
                      loading={isLoadingUsagesForDownloadByCsv}
                      disabled={isLoadingUsagesForDownloadByCsv}
                      fullWidth
                    >
                      CSV
                    </LoadingButton>
                  </Box>
                </Box>
                {usagesForDownloadMessageByCsv && (
                  <CustomAlert
                    severity={
                      usagesForDownloadByCsv.length > 0 ? "success" : "info"
                    }
                    text={usagesForDownloadMessageByCsv}
                    onClose={() => dispatch(cleanUsagesForDownloadMessage())}
                    sx={{ mt: "1rem" }}
                  />
                )}
              </Box>
            </AccordionDetails>
          </CustomizedAccordion>
        </Box>
      )}
    </>
  );
};

export default Download;

const CustomizedAccordion = styled(Accordion)`
  & .MuiPaper-root {
    border-radius: 1rem;
    box-shadow: none;
  }
  border-radius: 1rem;
  box-shadow: none;
  border: 1px solid rgba(224, 224, 224, 1);
 
  }
`;
