import { forwardRef, useImperativeHandle, useRef } from "react";

import {
  CustomAlert,
  CustomModal,
  CustomModalTypes,
  LoadingButton,
} from "@enerbit/base";
import { Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getTitleConfirmModal } from "../../../helpers/LabelUtils/labelsModal";
import { StateStorage } from "../../../models/StateStorage";
import {
  postEnerbitElectricitySupplyService,
  postEnerbitServiceAgreementsRelationships,
  postEnerbitServiceAgreementsRelationshipsChangePlan,
} from "../../../store/actions/electricity-supply-service/electricity-supply-service.actions";
import { onResetVars } from "../../../store/slices/planManagementSlice";
import { AppDispatch } from "../../../store/store";

const ConfirmChange = forwardRef((props, ref) => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    startedAt,
    enerbitServiceAgreementPlan,
    enerbitServiceAgreement,
    isCreatingESS,
    wasCreatedESS,
    wasCreateEnerbitService,
    wasAssignPlanEnerbitService,
  } = useSelector((state: StateStorage) => state.planManagement);

  const createClient = useRef<CustomModalTypes>(null);
  const { service } = useSelector((state: StateStorage) => state.information);
  const { typeChangePlan } = useSelector((state: StateStorage) => state.usages);

  useImperativeHandle(ref, () => ({
    changeModal() {
      if (createClient.current) {
        createClient.current.changeModal();
      }
    },
  }));

  const dispatchChangeTypePlan = () => {
    if (typeChangePlan === "alreadyHasAPlan") {
      dispatch(
        postEnerbitServiceAgreementsRelationshipsChangePlan({
          electricity_supply_service_id: service?.id ?? "",
          to_service_agreement_id: enerbitServiceAgreementPlan?.id ?? "",
          started_at: startedAt.toISOString(),
        })
      );
    } else if (typeChangePlan === "assignPlan") {
      dispatch(
        postEnerbitServiceAgreementsRelationships({
          enerbit_electricity_supply_service_id:
            service?.enerbit_electricity_supply_service?.id ?? "",
          enerbit_electricity_supply_service_agreement_id:
            enerbitServiceAgreementPlan?.id ?? "",
          fixed_enerbit_electricity_supply_services_id:
            service?.fixed_enerbit_electricity_supply_service?.id ?? "",
          started_at: startedAt.toISOString(),
        })
      );
    } else if (typeChangePlan === "createService") {
      dispatch(
        postEnerbitElectricitySupplyService({
          user_id: service?.owner_id ?? "",
          electricity_supply_service_id: service?.id ?? "",
          cuttable: service?.cuttable ?? false,
          service_status_id: service?.service_status_id ?? "",
          started_at: startedAt.toISOString(),
          service_agreement_id: enerbitServiceAgreementPlan?.id ?? "",
          ended_at: null,
        })
      );
    }
  };

  return (
    <CustomModal
      maxWidth="xs"
      ref={createClient}
      onClose={() => {
        dispatch(onResetVars());
      }}
      dialogContent={
        <>
          <Box sx={{ fontWeight: "400", mb: "0.5rem" }} className="Modal-title">
            {enerbitServiceAgreementPlan &&
              getTitleConfirmModal(typeChangePlan, enerbitServiceAgreementPlan)}
          </Box>
          <Box className="Modal-button-container">
            <Button
              color="warning"
              size="small"
              className="Modal-button-logout-cancel"
              onClick={() => {
                if (createClient.current) {
                  createClient.current.changeModal();
                }
              }}
              sx={{ width: "40%" }}
            >
              <Box style={{ fontWeight: "bold", margin: 0 }}>Volver</Box>
            </Button>

            <LoadingButton
              fullWidth
              variant="contained"
              color="secondary"
              disabled={false}
              loading={isCreatingESS}
              onClick={() => {
                dispatchChangeTypePlan();
              }}
            >
              Guardar
            </LoadingButton>
          </Box>
          {wasCreatedESS != null && (
            <CustomAlert
              onClose={() => {}}
              sx={{ marginTop: "20px", backgroundColor: "#d1fadf !important" }}
              severity={wasCreatedESS ? "success" : "error"}
              text={
                wasCreatedESS
                  ? "El plan fue cambiado con éxito"
                  : "No se pudo cambiar el plan, por favor intente nuevamente"
              }
            />
          )}
        </>
      }
    />
  );
});

export default ConfirmChange;
