import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { EnerbitPagination } from "@enerbit/base";
import { getServerErrorMessage } from "@enerbit/base/common/utils/serverErrorMessages";
import { removeTagFromList } from "../../helpers/tagsHelpers";
import {
  ErrorPayload,
  SuccessPayload,
} from "../../models/store/payload-types/payloadTypes";
import { DeleteTagRelationshipResponse } from "../../models/store/response-types/DeleteTagRelationshipResponse";
import { TagRelationshipResponse } from "../../models/store/response-types/TagRelationshipResponse";
import { TagsState } from "../../models/store/states/TagsState";
import { Tag } from "../../models/tags/Tags";
import {
  createTagRelationship,
  deleteTagRelationship,
  getTags,
  searchTagRelationships,
} from "../actions/tags/tags.actions";

const initialState: TagsState = {
  tags: { items: [], page: 0, size: 0, total: 0, pages: 0 },
  isLoadingTags: false,
  errorTags: null,
  newTags: { items: [], page: 0, size: 0, total: 0, pages: 0 },
  tagsRelationshipService: [],
  isLoadingCreateTagRelationship: false,
  successCreateTagRelationship: "",
  errorCreateTagRelationship: "",
  isDoneCreateTagRelationship: false,
  searchTagRelationshipsResult: {
    items: [],
    page: 0,
    size: 0,
    total: 0,
    pages: 0,
  },
  isLoadingSearchTagRelationshipsResult: false,
  errorSearchTagRelationshipsResult: "",
  deleteTagRelationship: null,
  isLoadingDeleteTagRelationship: false,
  successDeleteTagRelationship: "",
  errorDeleteTagRelationship: "",
  isDoneDeleteTagRelationship: false,
};

export const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    addNewTag: (state, { payload }: PayloadAction<Tag>) => {
      state.newTags?.items.push(payload);
    },
    addTagServiceRelationship: (state, { payload }: PayloadAction<Tag[]>) => {
      state.tagsRelationshipService?.push(...payload);
    },
    deleteTag: (
      state,
      {
        payload,
      }: PayloadAction<{
        listName: "newTags" | "tagsRelationshipService";
        tagId: string;
      }>
    ) => {
      const { listName, tagId } = payload;
      if (listName === "newTags" && state.newTags) {
        state.newTags.items = removeTagFromList(state.newTags.items, tagId);
      } else if (
        listName === "tagsRelationshipService" &&
        state.tagsRelationshipService
      ) {
        const updatedList = removeTagFromList(
          state.tagsRelationshipService,
          tagId
        );
        state.tagsRelationshipService = updatedList;
      }
    },
    cleanStoreTags: (state) => {
      return initialState;
    },
    cleanStoreNewTags: (state) => {
      state.newTags = initialState.newTags;
    },
    cleanDeleteTagsState: (state) => {
      state.isLoadingDeleteTagRelationship =
        initialState.isLoadingDeleteTagRelationship;
      state.successDeleteTagRelationship =
        initialState.successDeleteTagRelationship;
      state.errorDeleteTagRelationship =
        initialState.errorDeleteTagRelationship;
      state.isDoneDeleteTagRelationship =
        initialState.isDoneDeleteTagRelationship;
    },
    cleanCreateTagRelationshipStatus: (state) => {
      state.successCreateTagRelationship = "";
      state.errorCreateTagRelationship = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTags.pending, (state) => {
        state.isLoadingTags = true;
        state.errorTags = null;
      })
      .addCase(
        getTags.rejected.type,
        (state, action: PayloadAction<ErrorPayload>) => {
          state.isLoadingTags = false;
          const customErrorMessages = {
            404: "Error consultando los tags",
            500: "Error del servidor. No se encontraron tags",
          };
          state.errorTags = getServerErrorMessage(
            action.payload.status,
            customErrorMessages
          );
        }
      )
      .addCase(
        getTags.fulfilled.type,
        (
          state,
          action: PayloadAction<SuccessPayload<EnerbitPagination<Tag>>>
        ) => {
          state.isLoadingTags = false;
          state.tags = {
            items: action.payload.data.items,
            page: action.payload.data.page,
            size: action.payload.data.items.length,
            total: action.payload.data.total,
            pages: action.payload.data.pages,
          };
        }
      )
      .addCase(createTagRelationship.pending, (state) => {
        state.isLoadingCreateTagRelationship = true;
        state.errorCreateTagRelationship = null;
      })
      .addCase(
        createTagRelationship.rejected.type,
        (state, action: PayloadAction<ErrorPayload>) => {
          state.isLoadingCreateTagRelationship = false;
          const customErrorMessages = {
            400: "No pudimos agregar la etiqueta. Por favor, intenta nuevamente.",
            404: "No pudimos agregar la etiqueta. Por favor, intenta nuevamente.",
            403: "No tienes los permisos necesarios.",
            500: "No pudimos agregar la etiqueta. Por favor, intenta nuevamente.",
          };

          state.errorCreateTagRelationship = getServerErrorMessage(
            action.payload.status,
            customErrorMessages
          );
        }
      )
      .addCase(
        createTagRelationship.fulfilled.type,
        (
          state,
          action: PayloadAction<SuccessPayload<EnerbitPagination<Tag>>>
        ) => {
          const customErrorMessages = {
            201: "La etiqueta fue agregada con éxito",
            200: "La etiqueta fue agregada con éxito",
          };
          state.successCreateTagRelationship = getServerErrorMessage(
            action.payload.status,
            customErrorMessages
          );
          state.tags = {
            items: action.payload.data.items,
            page: action.payload.data.page,
            size: action.payload.data.size,
            total: action.payload.data.total,
            pages: action.payload.data.pages,
          };
          state.errorCreateTagRelationship = null;
          state.isLoadingCreateTagRelationship = false;
          state.isDoneCreateTagRelationship = true;
          state.errorSearchTagRelationshipsResult = null;
        }
      )
      .addCase(searchTagRelationships.pending, (state) => {
        state.isLoadingSearchTagRelationshipsResult = true;
        state.errorSearchTagRelationshipsResult = null;
      })
      .addCase(
        searchTagRelationships.fulfilled,
        (
          state,
          action: PayloadAction<
            SuccessPayload<EnerbitPagination<TagRelationshipResponse>>
          >
        ) => {
          state.isLoadingSearchTagRelationshipsResult = false;
          state.searchTagRelationshipsResult = {
            items: action.payload.data.items,
            page: action.payload.data.page,
            size: action.payload.data.size,
            total: action.payload.data.total,
            pages: action.payload.data.pages,
          };
          state.errorCreateTagRelationship = null;
        }
      )
      .addCase(
        searchTagRelationships.rejected.type,
        (state, action: PayloadAction<ErrorPayload>) => {
          state.isLoadingSearchTagRelationshipsResult = false;
          const customErrorMessages = {
            404: "Error consultando los tags",
            500: "Error del servidor.",
          };
          state.errorSearchTagRelationshipsResult = getServerErrorMessage(
            action.payload.status,
            customErrorMessages
          );
        }
      )
      .addCase(deleteTagRelationship.pending, (state) => {
        state.isLoadingDeleteTagRelationship = true;
        state.errorDeleteTagRelationship = null;
      })
      .addCase(
        deleteTagRelationship.fulfilled,
        (
          state,
          action: PayloadAction<SuccessPayload<DeleteTagRelationshipResponse>>
        ) => {
          const customErrorMessages = {
            200: "La etiqueta fue eliminada con éxito",
            201: "La etiqueta fue eliminada con éxito",
          };
          state.isLoadingDeleteTagRelationship = false;
          state.successDeleteTagRelationship = getServerErrorMessage(
            action.payload.status,
            customErrorMessages
          );
          state.isDoneDeleteTagRelationship = true;
          state.deleteTagRelationship = action.payload.data;
          state.errorDeleteTagRelationship = null;
        }
      )
      .addCase(
        deleteTagRelationship.rejected.type,
        (state, action: PayloadAction<ErrorPayload>) => {
          state.isLoadingDeleteTagRelationship = false;
          const customErrorMessages = {
            400: "No pudimos eliminar la etiqueta. Por favor, intenta nuevamente.",
            404: "No pudimos eliminar la etiqueta. Por favor, intenta nuevamente.",
            500: "No pudimos eliminar la etiqueta. Por favor, intenta nuevamente.",
            403: "No tienes los permisos necesarios.",
          };
          state.errorDeleteTagRelationship = getServerErrorMessage(
            action.payload.status,
            customErrorMessages
          );
        }
      );
  },
});

export const {
  addNewTag,
  addTagServiceRelationship,
  deleteTag,
  cleanStoreTags,
  cleanStoreNewTags,
  cleanDeleteTagsState,
  cleanCreateTagRelationshipStatus,
} = tagsSlice.actions;

export default tagsSlice.reducer;
