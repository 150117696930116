import { EnergyReactiveUnit, EnergyUnit } from "./EnergyUnit";

export class Energy {
  constructor(
    public unit: EnergyUnit,
    public reactiveUnit: EnergyReactiveUnit,
    public value: number
  ) {}

  public toCustomString = (
    decimals: number,
    withUnits: boolean,
    withReactiveUnits: boolean,
    onlyReactiveUnit: false
  ) => {
    let result = this.value
      .toFixed(decimals)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (onlyReactiveUnit) {
      result += " " + this.reactiveUnit;
      return result;
    }
    if (withUnits) {
      result += " " + this.unit;
    }
    if (withReactiveUnits) {
      result += " " + this.reactiveUnit;
    }
    return result;
  };

  public toString = () => {
    return `${this.value} ${this.unit}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
}

interface ConvertToEnergyProps {
  value: number;
  fixedUnit?: EnergyUnit | EnergyReactiveUnit;
}

export abstract class EnergyConversor {
  public static convertToEnergy = ({
    value,
    fixedUnit,
  }: ConvertToEnergyProps) => {
    if (fixedUnit == "kWh" || fixedUnit == "kVArh") {
      return new Energy("kWh", "kVArh", value / 1000);
    }
    if (fixedUnit == "MWh" || fixedUnit == "MVArh") {
      return new Energy("MWh", "MVArh", value / 1000000);
    }
    if (fixedUnit == "Wh" || fixedUnit == "VArh") {
      return new Energy("Wh", "VArh", value);
    }

    if (value > -1000 && value < 1000) {
      return new Energy("Wh", "VArh", value);
    } else if (value > -1000000 && value < 1000000) {
      return new Energy("kWh", "kVArh", value / 1000);
    } else {
      return new Energy("MWh", "MVArh", value / 1000000);
    }
  };
}
