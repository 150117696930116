import { EnerbitPagination, api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { Frontier } from "../../../models/assignees/frontiers";
import { Leads } from "../../../models/assignees/ILeads";

export const getFrontiers = createAsyncThunk(
  //action type string
  "[Assignees] Get Frontiers",
  //callback function
  async (
    measurement_point_id: string,
    thunkAPI
  ): Promise<EnerbitPagination<Frontier> | any> => {
    try {
      const { data } = await (api as AxiosInstance).get(
        `assignees/frontiers?measurement_point_id=${measurement_point_id}`
      );
      return data;
    } catch (error: any) {
      console.error(error);
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const getLeads = createAsyncThunk<
  EnerbitPagination<Leads>,
  string,
  { rejectValue: AxiosError }
>("getLeads", async (measurementPointId: string, thunkAPI) => {
  try {
    const response: AxiosResponse<EnerbitPagination<Leads>> = await (
      api as AxiosInstance
    ).get(
      `/assignees/leads?measurement_point_id=${measurementPointId}&page=1&size=50`
    );
    return response.data;
  } catch (error: any) {
    console.error(error);
    const axiosError: AxiosError = error as AxiosError;
    return thunkAPI.rejectWithValue(axiosError);
  }
});
