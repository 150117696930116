import { Box, CircularProgress, theme, Typography } from "@enerbit/base";
import { useSelector } from "react-redux";
import { StateStorage } from "../../../models/StateStorage";
import Download from "./download/Download";
import DropdownOptions from "./usage-table/DropdownOptions";
import UsageTable from "./usage-table/UsageTable";

const UsagesPage = () => {
  const { selectUsagesTable, isLoadingUsages } = useSelector(
    (state: StateStorage) => state.usages
  );

  return (
    <>
      <DropdownOptions />
      {isLoadingUsages && (
        <Box
          display="flex"
          gap="1rem"
          sx={{ my: "24px", alignItems: "center" }}
        >
          <Typography color={theme.palette.primary.main}>
            Cargando consumos...
          </Typography>
          <CircularProgress />
        </Box>
      )}
      <UsageTable typeConsumption={selectUsagesTable} />
      {/* <PeriodComparison /> */}
      <Download />
    </>
  );
};

export default UsagesPage;
