import { enerbitColors, WarningIcon } from "@enerbit/base";
import { Box, styled, Typography } from "@mui/material";
import { SpanStatusProps } from "../models/props/SpanStatus";

export const renderErrorMessage = (errorMessage: string) => (
  <Box sx={{ display: "flex", alignItems: "center", mt: "0.8rem" }}>
    <WarningIcon
      style={{
        color: enerbitColors.error.main,
        margin: "0 0.5rem 0 0.2rem",
      }}
    />
    <TypographyStatus isred={1}>{errorMessage}</TypographyStatus>
  </Box>
);
const TypographyStatus = styled(Typography)(({ isred }: SpanStatusProps) => ({
  fontWeight: 400,
  color: isred ? enerbitColors.error.main : "white",
  display: "inline",
}));
