import {
  Box,
  Button,
  DifferenceOutlinedIcon,
  EventOutlinedIcon,
  InsertChartOutlinedIcon,
  ModeOfTravelOutlinedIcon,
  RuleFolderOutlinedIcon,
  enerbitColors,
} from "@enerbit/base";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SectionCardInformation from "../../../helpers/CardInformationGeneral/SectionCardInformation";
import { StateStorage } from "../../../models/StateStorage";
import { postEnerbitServiceAgreementsRelationshipsChangePlan } from "../../../models/electricity-supply-services/EnerbitESS";
import { getEnerbitServiceAgreementsRelationships } from "../../../store/actions/electricity-supply-service/electricity-supply-service.actions";
import { setIndexTab } from "../../../store/slices/informationSlice";
import { setValueTypeChangePlan } from "../../../store/slices/usagesSlice";
import { AppDispatch } from "../../../store/store";
import {
  enerBitPlanGenerationAndUnitCostCoverage,
  enerBitPlanTechToolCharges,
} from "../../../utils/enerBitPlansUtils";
import { ModalChangePlan } from "../../modal-change-plan/ModalChangePlan";

export type EnerbitServiceAgreementCardProps = {
  enerbitElectricitySupplyService?: postEnerbitServiceAgreementsRelationshipsChangePlan | null;
};

export const EnerbitServiceAgreementCard = ({
  enerbitElectricitySupplyService,
}: EnerbitServiceAgreementCardProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = useState(false);
  const {
    wasAssignPlanEnerbitService,
    wasCreateEnerbitService,
    enerbitServiceAgreementsRelationships,
    enerbitServiceAgreementsRelationshipsError,
  } = useSelector((state: StateStorage) => state.planManagement);
  const { typeChangePlan } = useSelector((state: StateStorage) => state.usages);

  useEffect(() => {
    let value = typeChangePlan;
    if (enerbitElectricitySupplyService === undefined || null) {
      value = "createService";
    } else if (
      enerbitElectricitySupplyService?.enerbit_service_agreement === null
    ) {
      value = "assignPlan";
    } else if (enerbitElectricitySupplyService?.enerbit_service_agreement) {
      value = "alreadyHasAPlan";
    }
    dispatch(setValueTypeChangePlan(value));
  }, [enerbitElectricitySupplyService]);

  useEffect(() => {
    if (enerbitElectricitySupplyService?.id) {
      dispatch(
        getEnerbitServiceAgreementsRelationships(
          enerbitElectricitySupplyService.id
        )
      );
    }
  }, [enerbitElectricitySupplyService?.id, dispatch]);

  const enerbitPlanStartDate =
    enerbitServiceAgreementsRelationships?.items.find(
      (item) => item.is_active === true
    );

  useEffect(() => {
    if (wasAssignPlanEnerbitService || wasCreateEnerbitService) {
      setOpen(false);
    }
  }, [wasAssignPlanEnerbitService, wasCreateEnerbitService]);

  return (
    <>
      {enerbitElectricitySupplyService?.enerbit_service_agreement && (
        <SectionCardInformation
          titleText={"Información del plan"}
          titleColor={"#E26A04"}
          bodyColor={enerbitColors?.neutral[700]}
          background={"#FFFDDB"}
          IconTitle={ModeOfTravelOutlinedIcon}
          showButtonTop={false}
          showButtonBottom={{
            shouldShowButtonBottom: false,
            textButton:
              typeChangePlan === "alreadyHasAPlan"
                ? "Cambiar plan de servicio eB"
                : undefined,
            onClick: () => {
              {
                typeChangePlan === "alreadyHasAPlan" && setOpen(true);
              }
            },
            Icon: InsertChartOutlinedIcon,
          }}
          onClick={() => {
            dispatch(setIndexTab(2));
          }}
          data={[
            {
              label: "Plan actual:",
              value:
                enerbitElectricitySupplyService?.enerbit_service_agreement
                  ?.name ?? "No se encuenta nombre de plan",
              Icon: RuleFolderOutlinedIcon,
            },
            {
              label: "Fecha inicio:",
              value: enerbitPlanStartDate
                ? moment(enerbitPlanStartDate.started_at).format("YYYY-MM-DD")
                : enerbitServiceAgreementsRelationshipsError,
              Icon: EventOutlinedIcon,
            },
            {
              label: "Fecha fin:",
              value: enerbitPlanStartDate?.ended_at
                ? moment(enerbitPlanStartDate?.ended_at).format("YYYY-MM-DD")
                : "Indefinido",
              Icon: EventOutlinedIcon,
            },
            {
              label: "Cobertura:",
              value: enerBitPlanGenerationAndUnitCostCoverage({
                enerbitElectricitySupplyService,
              }),
              Icon: DifferenceOutlinedIcon,
            },
            {
              label: "Herramientas tec:",
              value: enerBitPlanTechToolCharges({
                enerbitElectricitySupplyService,
              }),
              Icon: InsertChartOutlinedIcon,
            },
          ]}
        />
      )}

      {typeChangePlan === "createService" && (
        <Box className="custom-card-box">
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpen(true);
            }}
          >
            Crear servicio eB
          </Button>
        </Box>
      )}

      {typeChangePlan === "assignPlan" && (
        <Box className="custom-card-box">
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpen(true);
            }}
          >
            Asignar plan a servicio eB
          </Button>
        </Box>
      )}

      <ModalChangePlan
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
};
