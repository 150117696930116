import {
  AccessTimeOutlinedIcon,
  AdapterMoment,
  CalendarTodayOutlinedIcon,
  DatePicker,
  enerbitColors,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
  Stack,
  styled,
} from "@enerbit/base";
import React from "react";

import moment, { Moment } from "moment";
import { PickerProps } from "../../models/props/Picker";

moment.updateLocale("es", { week: { dow: 0 } }); // 0-6 (Sunday-Saturday)

interface CustomPickerDayProps extends PickersDayProps<Moment> {
  dayIsBetween: boolean;
  isFirstDay: boolean;
  isLastDay: boolean;
}

// TODO: Este componente debería de ser un componente del base
const CustomDatePicker = ({
  value,
  isLabel,
  icon,
  typePicker,
  onChange,
  minDate,
  maxDate,
}: PickerProps) => {
  const renderWeekPickerDay = (
    date: Moment,
    selectedDates: Array<Moment | null>,
    pickersDayProps: PickersDayProps<Moment>
  ) => {
    if (!value) {
      return <PickersDay {...pickersDayProps} />;
    }
    const start: Moment = moment(value).startOf("week");
    const end: Moment = moment(value).endOf("week");

    const dayIsBetween = date.isBetween(start, end, null, "[]");
    const isFirstDay = date.isSame(start, "day");
    const isLastDay = date.isSame(end, "day");

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };
  return (
    <>
      {typePicker !== "Semana" && (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Stack spacing={3} sx={{ width: "100%" }}>
            <DatePicker
              views={
                typePicker === "Mes"
                  ? ["year", "month"]
                  : ["year", "month", "day"]
              }
              format={typePicker === "Mes" ? "YYYY-MM" : "YYYY-MM-DD"}
              {...(isLabel && { label: "Selecciona un mes" })}
              openTo={typePicker === "Mes" ? "month" : "day"}
              disableFuture
              value={value}
              onChange={onChange}
              slots={{
                openPickerIcon:
                  icon === "calendar"
                    ? CalendarTodayOutlinedIcon
                    : AccessTimeOutlinedIcon,
              }}
              minDate={minDate}
              maxDate={maxDate}
            />
          </Stack>
        </LocalizationProvider>
      )}
    </>
  );
};

export default CustomDatePicker;

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: enerbitColors.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
})) as React.ComponentType<CustomPickerDayProps>;
