import {
  enerbitColors,
  serviceNames,
  thirdPartyServiceNames,
} from "@enerbit/base";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import { Box, CircularProgress, Typography } from "@mui/material";
import { navigateToUrl } from "single-spa";
import { IServiceRelationships } from "../../../models/electricity-supply-services/IServiceRelationships";

interface servicesData {
  serviceList: IServiceRelationships[];
  isLoadingServiceList: boolean;
  serviceListError: string;
}

const ServicesItems = ({
  serviceList,
  isLoadingServiceList,
  serviceListError,
}: servicesData) => {
  const servicesData = serviceList.map((service) => ({
    value:
      (service?.service_type_name
        ? thirdPartyServiceNames[service.service_type_name]
        : undefined) ||
      (service?.service_type_name
        ? serviceNames[service.service_type_name]
        : null),
    showButtonItem: {
      shouldShowButton: true,
      onClick: () => {
        navigateToUrl(`/#/reading-records/detail/${"489673"}`);
      },
    },
  }));

  return (
    <>
      {isLoadingServiceList && (
        <Box display="flex" gap="1rem" alignItems="center" ml="0.2rem">
          <CircularProgress
            sx={{ height: "1rem !important", width: "1rem !important" }}
          />{" "}
          <Typography color={enerbitColors?.neutral[700]} fontSize="0.75rem">
            Cargando información de servicios...
          </Typography>{" "}
        </Box>
      )}
      {!serviceListError ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            {servicesData.map(
              (service, index) =>
                service.value && (
                  <Box
                    key={index}
                    sx={{
                      padding: "0.5rem 1.1rem !important",
                      alignItems: "center",
                      pl: "0 !important",
                      margin: "0rem 0rem 0rem 0.5rem",
                    }}
                  >
                    {service.showButtonItem.shouldShowButton && (
                      <>
                        <Box
                          sx={{
                            padding: "0 !important",
                            background: "transparent",
                            borderRadius: "15%",
                            display: "flex",
                            alignItems: "center",
                            // "&:hover": {
                            //   background: "none",
                            //   color: theme.palette.secondary.main,
                            //   "& .MuiTypography-root, & .MuiSvgIcon-root, & span":
                            //     {
                            //       background: "none",
                            //       color: theme.palette.secondary.main,
                            //     },
                            // },
                          }}
                          // onClick={service.showButtonItem.onClick}
                        >
                          <ArrowRightOutlinedIcon
                            sx={{
                              color: enerbitColors.neutral[700],
                              fontSize: "1.3rem",
                            }}
                          />

                          <Typography
                            sx={{
                              fontSize: "0.75rem",
                              mr: "0.4rem",
                            }}
                          >
                            {service.value}
                          </Typography>
                        </Box>
                      </>
                    )}
                  </Box>
                )
            )}
          </Box>
        </>
      ) : (
        <Typography sx={{ marginLeft: "0.3rem" }}>
          {serviceListError}
        </Typography>
      )}
    </>
  );
};

export default ServicesItems;
