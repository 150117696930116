export const translateRoles = (rol: RolesName): string => {
  switch (rol) {
    case RolesName.admin:
      return "Administrador";
    case RolesName.owner:
      return "Propietario";
    case RolesName.stakeholder:
      return "Interesado";
    case RolesName.tenant:
      return "Inquilino";
    case RolesName.billable:
      return "Pagador";
  }
};

export enum RolesName {
  admin = "admin",
  owner = "owner",
  stakeholder = "stakeholder",
  tenant = "tenant",
  billable = "billable",
}
