import { CloseIcon, enerbitColors, InfoOutlinedIcon } from "@enerbit/base";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ItemGetOrders } from "../../../../models/interfaces/ItemGetOrders";
import { StateStorage } from "../../../../models/StateStorage";
import { getCancelReasons } from "../../../../store/actions/service-orders/service-orders.actions";
import { cleanCodeErrorPermissionDeniedCancelReasons } from "../../../../store/slices/serviceOrdersSlice";
import { AppDispatch } from "../../../../store/store";
import { ServiceOrderCancellationModalContainer } from "./ServiceOrderCancellationModalContainer";

export type ServiceOrderCancellationModalProps = {
  orderItem: ItemGetOrders;
  open: boolean;
  handleClose?: (
    event: {},
    reason: "backdropClick" | "escapeKeyDown" | "submit"
  ) => void;
};

const ServiceOrderCancellationModal = ({
  open,
  handleClose,
  orderItem,
}: ServiceOrderCancellationModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { codeErrorPermissionDeniedCancelReasons } = useSelector(
    (state: StateStorage) => state.serviceOrders
  );
  const wasModalOpenPreviously = useRef(open);

  useEffect(() => {
    if (!wasModalOpenPreviously.current && open) {
      dispatch(getCancelReasons());
    }
    wasModalOpenPreviously.current = open;
  }, [dispatch, open]);

  useEffect(() => {
    if (codeErrorPermissionDeniedCancelReasons === 403 && handleClose) {
      handleClose({}, "submit");
      dispatch(cleanCodeErrorPermissionDeniedCancelReasons());
    }
  }, [codeErrorPermissionDeniedCancelReasons, handleClose]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="modal-change-modal-plan">
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            color={enerbitColors.primary.main}
            align="center"
            fontWeight="bold"
          >
            {"Cancelar orden de servicio"}
          </Typography>
          <IconButton
            onClick={(_) => {
              if (handleClose) {
                handleClose({}, "backdropClick");
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            marginTop: "17px",
            alignItems: "center",
          }}
        >
          <InfoOutlinedIcon
            sx={{ marginRight: "0.3rem", fontSize: "1.125rem" }}
            color="secondary"
          />
          <Typography
            variant="subtitle1"
            color={enerbitColors.neutral[700]}
            fontWeight={400}
            fontSize={"0.875rem"}
          >
            {
              "Para cancelar la orden de servicio, es necesario completar todos los campos."
            }
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <ServiceOrderCancellationModalContainer
            orderItem={orderItem}
            handleClose={handleClose}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default ServiceOrderCancellationModal;
