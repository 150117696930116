import { formatterPeso } from "@enerbit/base/common/helpers/formatterPeso";
import { postEnerbitServiceAgreementsRelationshipsChangePlan } from "../models/electricity-supply-services/EnerbitESS";
export type EnerbitServiceAgreementCardProps = {
  enerbitElectricitySupplyService?: postEnerbitServiceAgreementsRelationshipsChangePlan | null;
};

export const enerBitPlanGenerationAndUnitCostCoverage = ({
  enerbitElectricitySupplyService,
}: EnerbitServiceAgreementCardProps): string => {
  let resultGenerationAndUnitCostCoverage = "No disponible";

  if (
    enerbitElectricitySupplyService?.enerbit_service_agreement?.conditions
      .charges
  ) {
    for (const charge of enerbitElectricitySupplyService
      .enerbit_service_agreement.conditions.charges) {
      if (
        charge.name !== "fixed" &&
        charge.conditions.intervals?.length === 1
      ) {
        resultGenerationAndUnitCostCoverage =
          "G+C = " +
          formatterPeso.format(charge.conditions.intervals[0].price ?? 0) +
          " ";
        break;
      }
    }
  }

  return resultGenerationAndUnitCostCoverage;
};

export const enerBitPlanTechToolCharges = ({
  enerbitElectricitySupplyService,
}: EnerbitServiceAgreementCardProps): string => {
  let resultTechToolCharges = "";

  enerbitElectricitySupplyService?.enerbit_service_agreement?.conditions.charges.map(
    (charge, index) => {
      if (charge.name === "fixed") {
        const formattedPrice = formatterPeso.format(
          charge.conditions.price ?? 0
        );

        return (resultTechToolCharges += `${formattedPrice} + IVA\n`);
      }
    }
  );

  return resultTechToolCharges || "Valor no disponible";
};
