import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosInstance } from "axios";
import { PayloadGetUsages } from "../../../models/payloads/consumption/PayloadGetUsages";
import { Usage } from "../../../models/usages/Usage";

export const getUsages = createAsyncThunk(
  //action type string
  "consumption/getUsages",
  //callback function
  //Parameters: electricity_supply_service_id, since, until
  async (payload: PayloadGetUsages, thunkAPI): Promise<Usage[] | any> => {
    try {
      const { data } = await (api as AxiosInstance).get(
        `/electricity-supply-service/usages/electricity-service/${payload.electricity_supply_service_id}`,
        {
          params: {
            since: payload.since.format(),
            until: payload.until.format(),
            period_str: payload.period_str,
          },
        }
      );
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const getUsagesForDownloadByCsv = createAsyncThunk(
  //action type string
  "consumption/getUsagesForDownloadByCsv",
  //callback function
  //Parameters: electricity-supply-service-id , since, until
  async (payload: PayloadGetUsages, thunkAPI): Promise<Usage[] | any> => {
    try {
      const { data } = await (api as AxiosInstance).get(
        `/electricity-supply-service/usages/electricity-service/${payload.electricity_supply_service_id}`,
        {
          params: {
            since: payload.since.format(),
            until: payload.until.format(),
            period_str: payload.period_str,
          },
        }
      );
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
