import { enerbitColors } from "@enerbit/base";
import moment from "moment";
import {
  RelayStatus,
  ServiceErrorStatus,
} from "../models/information/RelayStatus";

export const determineStatusColor = ({
  isLoadingServiceReconnect,
  isLoadingServiceStatus,
  serviceStatus,
  serviceErrorObject,
}: {
  isLoadingServiceReconnect: boolean;
  isLoadingServiceStatus: boolean;
  serviceStatus: RelayStatus;
  serviceErrorObject: ServiceErrorStatus;
}) => {
  if (isLoadingServiceReconnect) {
    return enerbitColors.warning.main;
  } else if (isLoadingServiceStatus) {
    return enerbitColors.primary.main;
  } else if (serviceStatus.relay_status === "ON") {
    return enerbitColors.success.main;
  } else if (serviceStatus.relay_status === "OFF") {
    return enerbitColors.error.main;
  } else if (
    serviceErrorObject.status === "disconnected" ||
    serviceErrorObject.status === "timeout"
  ) {
    return enerbitColors.error.main;
  }
  return enerbitColors.primary.main;
};

export const determineBackgroundColor = ({
  serviceStatus,
  isLoadingServiceReconnect,
  backgroundColor,
}: {
  serviceStatus: RelayStatus;
  isLoadingServiceReconnect: boolean;
  backgroundColor?: string;
}) => {
  if (serviceStatus.relay_status === "OFF" && !isLoadingServiceReconnect) {
    return `${backgroundColor}`;
  } else if (isLoadingServiceReconnect && serviceStatus.relay_status === "ON") {
    return `${backgroundColor}`;
  } else {
    return `${backgroundColor}`;
  }
};

export const isDisabledButton = ({
  isLoadingServiceStatus,
  isLoadingServiceReconnect,
  serviceStatus,
  serviceErrorObject,
}: {
  isLoadingServiceStatus: boolean;
  isLoadingServiceReconnect: boolean;
  serviceStatus: RelayStatus;
  serviceErrorObject: ServiceErrorStatus;
}) => {
  const isLoading = isLoadingServiceStatus || isLoadingServiceReconnect;
  const isServiceOn = serviceStatus.relay_status === "ON";
  const hasServiceErrors = Object.entries(serviceErrorObject).length !== 0;

  return isLoading || isServiceOn || hasServiceErrors;
};

export const serviceActive = (ended_at: Date | null) => {
  let activeServiceDate = moment().isBefore(ended_at);
  let inactiveServiceDate = moment().isAfter(ended_at);
  if (ended_at === null) {
    return true;
  } else if (activeServiceDate) {
    return true;
  } else if (inactiveServiceDate) {
    return false;
  }
};
