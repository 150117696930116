import {
  Box,
  Button,
  DoDisturbOffOutlinedIcon,
  ErrorOutlineOutlinedIcon,
  EventOutlinedIcon,
  Typography,
  WarningIcon,
  enerbitColors,
  styled,
  theme,
} from "@enerbit/base";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import TitleComponent from "../../../../helpers/CardInformationGeneral/TitleComponent";
import { StateStorage } from "../../../../models/StateStorage";
import { ButtonConnectionProps } from "../../../../models/props/ButtonConnectionProps";
import { ConnectionStatusCardProps } from "../../../../models/props/ConnectionStatusCard";
import { setOpenConfirmReconnectDialog } from "../../../../store/slices/informationSlice";
import { AppDispatch } from "../../../../store/store";
import {
  determineBackgroundColor,
  determineStatusColor,
  serviceActive,
} from "../../../../utils/connectionCardUtils";

export const ConnectionCardInactive = () => {
  const {
    isLoadingServiceReconnect,
    service,
    serviceStatus,
    isLoadingServiceStatus,
    serviceErrorObject,
  } = useSelector((state: StateStorage) => state.information);

  const dispatch = useDispatch<AppDispatch>();
  return (
    <ConnectionStatusCard
      status={determineStatusColor({
        isLoadingServiceReconnect,
        isLoadingServiceStatus,
        serviceStatus,
        serviceErrorObject,
      })}
    >
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <TitleComponent
          color={theme.palette.primary.main}
          IconTitle={
            serviceActive(service?.ended_at!)
              ? ErrorOutlineOutlinedIcon
              : WarningIcon
          }
          titleText={"Estado del servicio"}
          showButtonTop={false}
        />

        <>
          {" "}
          <ButtonConnection
            bgcolor={determineBackgroundColor({
              serviceStatus,
              isLoadingServiceReconnect,
              backgroundColor: `${enerbitColors?.error[200]} !important`,
            })}
            colortext={`${enerbitColors?.error.main} !important`}
            size="small"
            variant="contained"
            fullWidth
            sx={{ m: "0.1rem 0 0.5rem 0", width: "30%" }}
            onClick={() => dispatch(setOpenConfirmReconnectDialog(true))}
            disabled={true}
          >
            <DoDisturbOffOutlinedIcon
              sx={{
                color: `${enerbitColors?.error.main} !important`,
                fontSize: "20px",
                fontWeight: "bold",
                marginRight: "0.3rem",
              }}
            />{" "}
            Inactivo
          </ButtonConnection>
        </>
      </Box>

      <>
        {" "}
        <Box
          sx={{
            display: "flex",
            marginBottom: "0.3rem",
            flexWrap: "wrap",
          }}
        >
          <span
            style={{
              color: enerbitColors?.neutral[700],
              fontWeight: "bold",
              marginLeft: "2px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <EventOutlinedIcon
              sx={{
                color: enerbitColors?.neutral[700],
                fontSize: "12px",
                marginRight: "0.2rem",
              }}
            />{" "}
            Fecha de finalización:
          </span>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <span
              style={{
                color: enerbitColors?.neutral[700],
                fontWeight: "400",
                marginLeft: "0.3rem",
              }}
            >
              {moment(service?.ended_at).format("YYYY-MM-DD")}
            </span>
          </Typography>
        </Box>
      </>
    </ConnectionStatusCard>
  );
};

const ConnectionStatusCard = styled(Box)(
  ({ status }: ConnectionStatusCardProps) => ({
    backgroundColor: `#FFFFFF`,
    border: `1px solid ${status}`,
    padding: "12px 16px 12px 12px",
    margin: "20px 0px 20px",
    borderRadius: "10px",
    position: "relative",
  })
);

const ButtonConnection = styled(Button)(
  ({ bgcolor, colortext }: ButtonConnectionProps) => ({
    borderRadius: "6px !important",
    fontWeight: "normal !important",
    backgroundColor: `${bgcolor}`,
    color: `${colortext}`,
    boxShadow: "unset !important",
    width: "100%",
    height: "1.9rem",
    fontSize: "0.875rem",
    //Hover
    "&:hover": {
      backgroundColor: `${bgcolor}`,
    },
    "&:disabled": {
      backgroundColor: `${bgcolor}`,
    },
  })
);
