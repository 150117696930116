import {
  BookmarkAddedOutlinedIcon,
  Box,
  Button,
  Card,
  CardContent,
  CardStatus,
  ErrorOutlineOutlinedIcon,
  Grid,
  MonetizationOnOutlinedIcon,
  ThirdPartyServiceTypeNames,
  Typography,
  enerbitColors,
  formatterPeso,
  serviceNames,
  thirdPartyServiceNames,
} from "@enerbit/base";
import { CardStatusEnum } from "@enerbit/base/common/components/CardStatus";
import {
  mountedDisassembleAppsDetailMemos,
  mountedDisassembleAppsEssBillingDetail,
} from "../../../../helpers/spa/spa.helper";
import { IBillingInvoice } from "../../../../models/interfaces/IBilling";

export const CardItemTableInvoice = ({
  document,
}: {
  document: IBillingInvoice;
}) => {
  const getCardStatus = (
    paymentStatus?: string
  ): CardStatusEnum | undefined => {
    switch (paymentStatus) {
      case "payed":
        return CardStatusEnum.payed;
      case "pending":
        return CardStatusEnum.pending;
      case "overdue":
        return CardStatusEnum.overdue;
    }
  };

  const getDocumentTypeColor = (
    documentType: string
  ): "primary" | "error" | "success" => {
    switch (documentType) {
      case "invoice":
        return "primary";
      case "debit_note":
        return "error";
      case "credit_note":
        return "success";
      default:
        return "primary"; // Default color if documentType doesn't match any case
    }
  };

  const isButtonDisabled = (document: IBillingInvoice): boolean => {
    console.log(document.service_type_name, document.document_type);
    return (
      (document.service_type_name ===
        ThirdPartyServiceTypeNames.billerbitSecurity ||
        document.service_type_name ===
          ThirdPartyServiceTypeNames.billerbitStreetLight ||
        document.service_type_name ===
          ThirdPartyServiceTypeNames.distributionInfrastructureFee) &&
      document.document_type !== "invoice"
    );
  };

  return (
    <>
      <Card variant="outlined" sx={{ borderRadius: "13px", mb: "10px" }}>
        <CardContent sx={{ display: "flex", padding: "10px 16px !important" }}>
          <Grid container sx={{ alignItems: "center" }}>
            <Grid item xs={12} md={4}>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Box>
                  <Button
                    variant="contained"
                    color={getDocumentTypeColor(document.document_type!)}
                    sx={{
                      padding: "0.6rem 0rem !important",
                      minWidth: "50px",
                      marginRight: "15px",
                    }}
                  >
                    {document.document_type === "invoice" ? (
                      <MonetizationOnOutlinedIcon />
                    ) : document.document_type === "credit_note" ? (
                      <BookmarkAddedOutlinedIcon />
                    ) : (
                      <ErrorOutlineOutlinedIcon />
                    )}
                  </Button>
                </Box>
                <Box>
                  {serviceNames[document.service_type_name!] ??
                    thirdPartyServiceNames[document.service_type_name!] ??
                    document.service_type_name}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <CardStatus status={getCardStatus(document.payment_status)!} />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography
                fontWeight={600}
                sx={{ color: enerbitColors.neutral[600] }}
              >
                Valor de la factura
              </Typography>
              <Typography>
                {formatterPeso.format(document.legal_payable_amount!)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                disabled={isButtonDisabled(document)}
                variant="contained"
                color="secondary"
                sx={{ width: "50%" }}
                onClick={() => {
                  if (document.document_type === "invoice") {
                    return mountedDisassembleAppsEssBillingDetail(
                      document.service_type_name!,
                      document.document_id!
                    );
                  } else if (document.document_type != "invoice") {
                    return mountedDisassembleAppsDetailMemos({
                      service_type_name: document.service_type_name!,
                      invoice_memo_id: document.document_id!,
                    });
                  }
                }}
              >
                Detalle
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Box>
        <Grid container>
          {/* biome-ignore lint/style/useSelfClosingElements: <explanation> */}
          <Box
            sx={{ width: "100%" }}
            id="single-spa-application:app-electricity-supply-service-create"
          ></Box>
        </Grid>
      </Box>
    </>
  );
};
