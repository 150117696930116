export interface postEnerbitServiceAgreementsRelationshipsChangePlan {
  cuttable: boolean;
  started_at: Date;
  ended_at: Date | null;
  id: string;
  service_status_id: string;
  enerbit_service_agreement: EnerbitServiceAgreement;
  electricity_supply_service_id: string;
}

export interface FixedEnerbitElectricitySupplyService {
  created_at: Date;
  cuttable: boolean;
  electricity_supply_service_id: string;
  ended_at: null;
  id: string;
  service_status_id: string;
  started_at: Date;
  update_at: null;
}

export interface EnerbitServiceAgreement {
  name: string;
  description: string;
  service_agreement_type: string;
  conditions: EnerbitServiceAgreementConditions;
  path_file: string;
  id: string;
}

export interface EnerbitServiceAgreementConditions {
  cycle_begin: string;
  cycle_end: string;
  charges: Charge[];
}

export interface Charge {
  name: ChargeName;
  conditions: ChargeConditions;
}

export interface ChargeConditions {
  price?: number;
  intervals?: Interval[];
}

export interface Interval {
  start: string;
  duration: string;
  price: number;
}

export enum ChargeName {
  Fixed = "fixed",
  Variable = "variable",
}
