export interface Frontier {
  frontier_xm_code: string;
  frontier_xm_registered_from: Date | undefined;
  lead_id: string;
  frontier_type: importExportStatus.ExportFrontier | importExportStatus.ImportFrontier;
  measurement_point_id: string;
  id: string;
  created_at: Date;
  updated_at: Date;
}

export enum importExportStatus {
  ExportFrontier = "export_frontier",
  ImportFrontier = "import_frontier",
}
