import { formatDate } from "@enerbit/base";
import {
  BroadcastOnHomeOutlinedIcon,
  EventOutlinedIcon,
  HelpOutlineOutlinedIcon,
  ModeOfTravelOutlinedIcon,
} from "@enerbit/base/common/helpers/iconsMaterial";
import { EnerbitPagination } from "@enerbit/base/common/models/EnerbitPagination";
import { Frontier, importExportStatus } from "../models/assignees/frontiers";

interface FrontierInfoItem {
  label: string;
  value?: string;
  Icon: React.ElementType;
}

export const findFrontierByType = (
  items: Frontier[],
  status: importExportStatus
): Frontier[] => items.filter((item) => item.frontier_type === status);

const addFrontierInfo = (
  items: FrontierInfoItem[],
  frontier: Frontier,
  labelPrefix: string
): void => {
  items.push(
    {
      label: `${labelPrefix}Frontera:`,
      value: frontier.frontier_xm_code,
      Icon: BroadcastOnHomeOutlinedIcon,
    },
    {
      label: `${labelPrefix}Fecha de registro ante XM:`,
      value: formatDate(frontier.frontier_xm_registered_from),
      Icon: EventOutlinedIcon,
    }
  );
};

export const validateTypeFrontier = (
  enerbitFrontiers: EnerbitPagination<Frontier> | null | undefined
): FrontierInfoItem[] => {
  const items: FrontierInfoItem[] = [];

  if (enerbitFrontiers?.items && enerbitFrontiers.items.length > 0) {
    const importFrontiers = findFrontierByType(
      enerbitFrontiers.items,
      importExportStatus.ImportFrontier
    );
    const exportFrontiers = findFrontierByType(
      enerbitFrontiers.items,
      importExportStatus.ExportFrontier
    );

    if (importFrontiers.length > 0) {
      items.push({ label: "Importadora", Icon: ModeOfTravelOutlinedIcon });
      addFrontierInfo(items, importFrontiers[0], "");
    } else {
      items.push({
        label: "¿Es frontera importadora?",
        value: "No",
        Icon: ModeOfTravelOutlinedIcon,
      });
    }

    if (exportFrontiers.length > 0) {
      items.push({ label: "Exportadora", Icon: ModeOfTravelOutlinedIcon });
      addFrontierInfo(items, exportFrontiers[0], "");
    } else {
      items.push({ label: "Exportadora", Icon: ModeOfTravelOutlinedIcon });
      items.push({
        label: "¿Es frontera?",
        value: "No",
        Icon: HelpOutlineOutlinedIcon,
      });
    }
  } else {
    items.push({
      label: "¿Es frontera?",
      value: "No",
      Icon: HelpOutlineOutlinedIcon,
    });
  }

  return items;
};
