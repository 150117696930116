import { styled } from "@enerbit/base";
import { useSelector } from "react-redux";
import LeftMenu from "../common/LeftMenu/LeftMenu";
import ListTabs from "../common/Tabs/ListTabs";
import type { StateStorage } from "../models/StateStorage";
import type { TabModel } from "../models/ui/Tab";
import { Information } from "./information/Information";
import ServiceOrdersContainer from "./service-orders/ServiceOrdersContainer";
import UsagesPage from "./usages/components/UsagesPage";
import { BillingPage } from "./billing/BillingPage";

const AttentionPage = () => {
	const { service } = useSelector((state: StateStorage) => state.information);

	const tabs: TabModel[] = [
		{
			label: "Facturación",
			component: <BillingPage />,
		},
		{
			label: "Consumos",
			component: <UsagesPage />,
			// biome-ignore lint/complexity/noUselessTernary: <explanation>
			disabled: service?.started_at ? false : true,
		},
		{
			label: "Información",
			component: <Information />,
		},
		{
			label: "Órdenes de servicio",
			component: <ServiceOrdersContainer />,
		},
	];

	return (
		<AttentionPageContainer>
			<LeftMenu />
			<ContentContainer>
				<ListTabs tabs={tabs} />
			</ContentContainer>
		</AttentionPageContainer>
	);
};

export default AttentionPage;

const AttentionPageContainer = styled("div")`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const ContentContainer = styled("div")`
  padding: 1.5rem 2.25rem 2.25rem 2rem;
  width: 100%;
`;
