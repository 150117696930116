import {
  AnimatePresence,
  Box,
  CustomAlert,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  moment,
  motion,
} from "@enerbit/base";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EmptyData } from "../../../../common/empty-data/EmptyData";
import { EEntity } from "../../../../common/enums/EServiceOrder";
import { StyledChip } from "../../../../common/style/StyledChip";
import TableSkeleton from "../../../../helpers/Loader/Skeleton/Orders/Table/TableSkeleton";
import { StateStorage } from "../../../../models/StateStorage";
import { getServicesOrders } from "../../../../store/actions/service-orders/service-orders.actions";
import { AppDispatch } from "../../../../store/store";
import { ButtonServiceOrderItem } from "./ButtonServiceOrderItem";

interface TableServicesOrdersPageProps {
  entity: EEntity;
  leadId?: string | undefined | null;
}

const entityDictionary: Record<EEntity, string> = {
  MP: "cliente",
  LD: "lead",
};

const TableServiceOrdersPage: React.FC<TableServicesOrdersPageProps> = ({
  entity,
  leadId,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { serviceOrders, isLoadingServiceOrders, errorServiceOrders } =
    useSelector((state: StateStorage) => state.serviceOrders);
  const { service } = useSelector((state: StateStorage) => state.information);
  const [pagination, setPagination] = useState<{
    page: number;
    size: number;
    total: number;
    pages: number;
    previous_page: number | null;
    next_page: number | null;
  }>({
    page: 0,
    size: 10,
    total: 0,
    pages: 0,
    previous_page: null,
    next_page: null,
  });

  useEffect(() => {
    if (serviceOrders) {
      const { page, size, total, pages, previous_page, next_page } =
        serviceOrders[entity];
      setPagination({
        page,
        size,
        total,
        pages,
        previous_page: previous_page ?? null,
        next_page: next_page ?? null,
      });
    }
  }, [serviceOrders]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPagination((prev) => ({ ...prev, page: value - 1 }));
  };

  useEffect(() => {
    if (service?.measurement_point_id) {
      const id =
        entity === EEntity.Client
          ? service?.measurement_point_id
          : leadId ?? "";
      dispatch(
        getServicesOrders({
          id: id,
          entity,
          dateRange: {
            startDate: "",
            endDate: "",
          },
          page: pagination.page,
        })
      );
    }
  }, [service?.measurement_point_id, dispatch, entity, pagination.page]);

  if (
    isLoadingServiceOrders[entity] ||
    (!isLoadingServiceOrders[entity] &&
      serviceOrders[entity]?.items === null &&
      errorServiceOrders[entity] === "")
  ) {
    return <TableSkeleton />;
  }

  if (errorServiceOrders[entity]) {
    return (
      <Box sx={{ mt: 2 }}>
        <CustomAlert
          severity="error"
          text={errorServiceOrders[entity]}
          onClose={() => null}
        />
      </Box>
    );
  }

  if (
    !serviceOrders ||
    !serviceOrders[entity] ||
    serviceOrders[entity]?.items?.length === 0
  ) {
    return (
      <EmptyData
        message={`No se encontraron órdenes para este ${entityDictionary[entity]}.`}
      />
    );
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
        <Table className="Table-services-orders" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                className="Table-th-items-services-orders"
              >
                ID orden
              </TableCell>
              <TableCell
                align="left"
                className="Table-th-items-services-orders"
              >
                Tipo de orden
              </TableCell>
              <TableCell
                align="left"
                className="Table-th-items-services-orders"
              >
                Fecha de registro
              </TableCell>
              <TableCell
                align="left"
                className="Table-th-items-services-orders"
              >
                Fecha de ejecución
              </TableCell>
              <TableCell
                align="left"
                className="Table-th-items-services-orders"
              >
                Estado
              </TableCell>
              <TableCell className="Table-th-items-services-orders">
                Acciones
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{
                backgroundColor: "transparent",
                "&:last-child td, &:last-child th": {
                  border: 0,
                  backgroundColor: "transparent !important",
                },
                height: "1.8rem",
              }}
            ></TableRow>
            <AnimatePresence>
              {serviceOrders &&
                serviceOrders[entity]?.items?.map((row, index) => {
                  return (
                    <TableRow
                      component={motion.tr}
                      layout
                      initial={{ opacity: 0, x: -10 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -10 }}
                      transition={{ delay: index * 0.25 }}
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        sx={{
                          borderTopLeftRadius: "8px",
                          borderBottomLeftRadius: "8px",
                        }}
                        align="left"
                      >
                        {" "}
                        {row.id}
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        {row.order_type.name}{" "}
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        {moment(row.created_at).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        {moment(row.planned_date_begin).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "0 4rem 0 0!important",
                          minWidth: "12rem",
                        }}
                      >
                        {row.status === "done" && (
                          <StyledChip
                            label="Completada"
                            sx={{
                              backgroundColor: "success.100",
                              color: "success.main",
                            }}
                          />
                        )}
                        {row.status === "pending" && (
                          <StyledChip
                            label="Pendiente"
                            sx={{
                              backgroundColor: "warning.200",
                              color: "warning.main",
                            }}
                          />
                        )}
                        {row.status === "cancel" && (
                          <StyledChip
                            label="Cancelada"
                            sx={{
                              backgroundColor: "error.100",
                              color: "error.main",
                            }}
                          />
                        )}
                        {row.status === "partial_done" && (
                          <StyledChip
                            label="Completada parcial"
                            sx={{
                              backgroundColor: "info.100",
                              color: "info.main",
                              border: "2px dashed",
                              borderColor: "info.main",
                            }}
                          />
                        )}
                        {row.status === "pending_assignment" && (
                          <StyledChip
                            label="En preparación"
                            sx={{
                              backgroundColor: "neutral.200",
                              color: "neutral.main",
                              border: "2px dashed",
                              borderColor: "neutral.main",
                            }}
                          />
                        )}
                      </TableCell>

                      <TableCell align="center">
                        <ButtonServiceOrderItem orderItem={row} />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </AnimatePresence>
          </TableBody>
        </Table>
      </TableContainer>
      <Box className="Pagination-services-orders" sx={{ mt: "1.5rem" }}>
        <Pagination
          count={pagination.pages}
          shape="rounded"
          page={pagination.page + 1}
          onChange={handlePageChange}
          sx={{ margin: "0.3rem 0 1rem 0" }}
        />
      </Box>
    </>
  );
};

export default TableServiceOrdersPage;
