import {
  AccountCircleOutlinedIcon,
  Box,
  ExpandMoreIcon,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  enerbitColors,
  theme,
} from "@enerbit/base";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Personalization,
  updatedUserServices,
} from "../../models/accounts/ServicesAccount";
import { setSelectedUser } from "../../store/slices/accountSlice";
import { AppDispatch } from "../../store/store";
import { RolesName, translateRoles } from "../roles/rolesName";

interface CustomizedSelectProps {
  appUsers: AppUsers[];
  appUser: updatedUserServices | null;
}

interface AppUsers {
  rol: RolesName | undefined;
  email?: string;
  userName: string;
  user_id?: string;
  service_account_id?: string;
  id?: string;
  started_at?: Date;
  ended_at?: null;
  personalizations?: Personalization[];
}

export const CustomizedSelect: React.FC<CustomizedSelectProps> = ({
  appUsers,
  appUser,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const filteredUsers = appUsers.filter((user) => user);
  const [userSelected, setUserSelected] = useState<AppUsers | undefined>(
    undefined
  );
  const handleChange = (event: SelectChangeEvent<string>) => {
    const userId = event.target.value;
    const user = filteredUsers.find((user) => user.user_id === userId);

    setUserSelected(user);
    if (user) {
      dispatch(setSelectedUser({ user: user as updatedUserServices }));
    }
  };

  useEffect(() => {
    if (appUser !== undefined && appUser) {
      setUserSelected(appUser);
      dispatch(setSelectedUser({ user: appUser }));
    }
  }, [appUser]);

  return (
    <>
      <FormControl
        sx={{
          width: "300px",
          boxShadow: "none",
          color: theme.palette.primary.main,
          "& .MuiSelect-select": {
            margin: "0 !important",
            padding: "16.5px 0 !important",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
      >
        <Select
          sx={{ padding: "0 !important" }}
          value={userSelected ? userSelected.user_id : ""}
          onChange={(event) => handleChange(event)}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          IconComponent={(props) => (
            <ExpandMoreIcon
              {...props}
              sx={{ color: `${theme.palette.primary.main} !important` }}
            />
          )}
        >
          {filteredUsers.map((user, index) => (
            <MenuItem
              key={user.user_id}
              value={user.user_id}
              sx={{
                "&:hover": {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              <Box
                key={index}
                sx={{
                  display: "flex",
                  padding: "0 !important",
                  margin: "0.9rem 0 !important",
                }}
              >
                <Box
                  sx={{
                    padding: "0 0 !important",
                    justifyContent: "flex-end",
                    minWidth: "1.5rem !important",
                  }}
                >
                  <Box
                    sx={{
                      background: theme.palette.primary.main,
                      marginRight: "0.8rem",
                      padding: "0.3rem 0.3rem",
                      borderRadius: "15%",
                      display: "flex",
                      border: `1px solid ${theme.palette.primary.main}`,
                    }}
                  >
                    <AccountCircleOutlinedIcon
                      sx={{ color: "white", fontSize: "1.563rem" }}
                    />
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "bold",
                      color:
                        userSelected && userSelected.user_id === user.user_id
                          ? theme.palette.primary.main
                          : enerbitColors?.primary[400],
                      textAlign: "left",
                      wordBreak: "break-word",
                      maxWidth: "200px",
                      whiteSpace: "normal",
                    }}
                  >
                    {user.userName}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "normal",
                      color:
                        userSelected && userSelected.user_id === user.user_id
                          ? theme.palette.primary.main
                          : enerbitColors?.primary[400],
                      textAlign: "left",
                    }}
                  >
                    {translateRoles(user.rol!)}
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
