import { Box, NavSidebar, PrivateRoute, ThemeConfig } from "@enerbit/base";

import { Provider } from "react-redux";
import { HashRouter, Route, Routes } from "react-router-dom";
import AttentionPage from "../pages/AttentionPage";
import { store } from "../store/store";
export default function Routers() {
  return (
    <Provider store={store}>
      <PrivateRoute>
        <ThemeConfig>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "100vh",
              width: "100vw",
              overflow: "hidden",
            }}
          >
            <NavSidebar />
            <Box className="Container-attention">
              <HashRouter>
                <Routes>
                  <Route
                    path="/electricity-supply-service/attention/:id"
                    element={<AttentionPage />}
                  />
                </Routes>
              </HashRouter>
            </Box>
          </div>
        </ThemeConfig>
      </PrivateRoute>
    </Provider>
  );
}
