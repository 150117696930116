import { EnerbitPagination } from "@enerbit/base";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import moment from "moment";
import { CreateServiceEnerbitResponse } from "../../models/electricity-supply-services/CreateServiceEnerbit";
import { EnerbitServiceAgreement } from "../../models/electricity-supply-services/EnerbitESS";
import { EnerbitServiceAgreementsRelationships } from "../../models/electricity-supply-services/EnerbitServiceAgreementsRelationships";
import { PlanManagementState } from "../../models/states/PlanManagementState";
import {
  AssignPlanEnerbitServiceResponse,
  CreateEnerbitServiceAgreementRelationshipResponse,
  getEnerbitElectricitySupplyService,
  getEnerbitServiceAgreementsRelationships,
  postEnerbitElectricitySupplyService,
  postEnerbitServiceAgreementsRelationships,
  postEnerbitServiceAgreementsRelationshipsChangePlan,
} from "../actions/electricity-supply-service/electricity-supply-service.actions";

const initialState: PlanManagementState = {
  startedAt: moment().startOf("month"),
  isLoadingEnerbitServiceAgreements: false,
  enerbitServiceAgreement: null,
  enerbitServiceAgreementPlan: null,
  isCreatingESS: false,
  wasCreatedESS: null,
  wasAssignPlanEnerbitService: null,
  wasCreateEnerbitService: null,
  enerbitServiceAgreementsRelationships: null,
  isLoadingEnerbitServiceAgreementsRelationships: false,
  enerbitServiceAgreementsRelationshipsError: "",
};

export const planManagementSlice = createSlice({
  name: "planManagement",
  initialState,
  reducers: {
    onEnerbitStartedAtChanged: (
      state,
      action: PayloadAction<moment.Moment>
    ) => {
      state.startedAt = action.payload;
    },
    onEnerbitServiceAgreementChanged: (
      state,
      action: PayloadAction<EnerbitServiceAgreement | null>
    ) => {
      state.enerbitServiceAgreement = action.payload;
    },
    onEnerbitServiceAgreementChangedPlan: (
      state,
      action: PayloadAction<EnerbitServiceAgreement | null>
    ) => {
      state.enerbitServiceAgreementPlan = action.payload;
    },
    onResetVars: (state) => {
      state.wasCreatedESS = null;
      state.wasAssignPlanEnerbitService = null;
      state.wasCreateEnerbitService = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(
      getEnerbitElectricitySupplyService.pending.type,
      (state) => {
        state.isLoadingEnerbitServiceAgreements = true;
        state.paginationEnerbitServiceAgreements = undefined;
      }
    );
    builder.addCase(
      getEnerbitElectricitySupplyService.rejected.type,
      (state) => {
        state.isLoadingEnerbitServiceAgreements = false;
      }
    );
    builder.addCase(
      getEnerbitElectricitySupplyService.fulfilled.type,
      (
        state,
        action: PayloadAction<EnerbitPagination<EnerbitServiceAgreement>>
      ) => {
        state.isLoadingEnerbitServiceAgreements = false;
        state.paginationEnerbitServiceAgreements = action.payload;
        if (!state.enerbitServiceAgreement && action.payload.items.length > 0) {
          state.enerbitServiceAgreement = action.payload.items[0];
        }
      }
    );
    builder.addCase(
      postEnerbitServiceAgreementsRelationshipsChangePlan.rejected.type,
      (state) => {
        state.wasCreatedESS = false;
        state.isCreatingESS = false;
      }
    );
    builder.addCase(
      postEnerbitServiceAgreementsRelationshipsChangePlan.pending.type,
      (state) => {
        state.isCreatingESS = true;
      }
    );
    builder.addCase(
      postEnerbitServiceAgreementsRelationshipsChangePlan.fulfilled.type,
      (
        state,
        action: PayloadAction<CreateEnerbitServiceAgreementRelationshipResponse>
      ) => {
        state.wasCreatedESS = true;
        state.isCreatingESS = false;
        state.enerbitServiceAgreement =
          state.paginationEnerbitServiceAgreements?.items.find(
            (e) =>
              e.id ===
              action.payload.enerbit_electricity_supply_service_agreement_id
          ) ?? null;
      }
    );
    builder.addCase(
      postEnerbitServiceAgreementsRelationships.rejected.type,
      (state) => {
        state.wasAssignPlanEnerbitService = false;
        state.isCreatingESS = false;
      }
    );
    builder.addCase(
      postEnerbitServiceAgreementsRelationships.pending.type,
      (state) => {
        state.isCreatingESS = true;
      }
    );
    builder.addCase(
      postEnerbitServiceAgreementsRelationships.fulfilled.type,
      (state, action: PayloadAction<AssignPlanEnerbitServiceResponse>) => {
        state.wasAssignPlanEnerbitService = true;
        state.isCreatingESS = false;
        state.enerbitServiceAgreementPlan = null;
        state.enerbitServiceAgreement = null;
      }
    );
    builder.addCase(
      postEnerbitElectricitySupplyService.pending.type,
      (state) => {
        state.isLoadingEnerbitServiceAgreements = true;
      }
    );
    builder.addCase(
      postEnerbitElectricitySupplyService.rejected.type,
      (state) => {
        state.isLoadingEnerbitServiceAgreements = false;
        state.wasCreateEnerbitService = false;
      }
    );
    builder.addCase(
      postEnerbitElectricitySupplyService.fulfilled.type,
      (state, action: PayloadAction<CreateServiceEnerbitResponse>) => {
        state.wasCreateEnerbitService = true;
        state.enerbitServiceAgreementPlan = null;
        state.enerbitServiceAgreement =
          state.paginationEnerbitServiceAgreements?.items.find(
            (e) =>
              e.id ===
              action.payload.enerbit_electricity_supply_service_agreement_id
          ) ?? null;
      }
    );
    builder.addCase(
      getEnerbitServiceAgreementsRelationships.pending.type,
      (state) => {
        state.isLoadingEnerbitServiceAgreements = true;
        state.enerbitServiceAgreementsRelationships = null;
      }
    );
    builder.addCase(
      getEnerbitServiceAgreementsRelationships.rejected.type,
      (state, action: PayloadAction<AxiosError>) => {
        state.isLoadingEnerbitServiceAgreements = false;
        if (action.payload?.response?.status === 404) {
          state.enerbitServiceAgreementsRelationshipsError =
            "No encontraron resultados";
        } else {
          state.enerbitServiceAgreementsRelationshipsError =
            "Error del servidor";
        }
      }
    );
    builder.addCase(
      getEnerbitServiceAgreementsRelationships.fulfilled.type,
      (
        state,
        action: PayloadAction<
          EnerbitPagination<EnerbitServiceAgreementsRelationships>
        >
      ) => {
        state.isLoadingEnerbitServiceAgreements = false;
        state.enerbitServiceAgreementsRelationships = action.payload;
        if (
          !state.enerbitServiceAgreementsRelationships &&
          action.payload.items.length > 0
        ) {
          state.enerbitServiceAgreementsRelationships = {
            items: [action.payload.items[0]],
            page: 0,
            size: 0,
            total: 0,
            pages: 0,
          };
        }
      }
    );
  },
});

export default planManagementSlice.reducer;
export const {
  onEnerbitStartedAtChanged,
  onEnerbitServiceAgreementChanged,
  onEnerbitServiceAgreementChangedPlan,
  onResetVars,
} = planManagementSlice.actions;
