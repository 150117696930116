import { EnerbitServiceAgreement } from "../../models/electricity-supply-services/EnerbitESS";

export const getTitleModal = (textTitleModal: string) => {
  switch (textTitleModal) {
    case "createService":
      return "Crear servicio eB";
    case "assignPlan":
      return "Asignar plan a servicio eB";
    default:
      return "Cambiar plan";
  }
};
export const getTitleLabel = (textLabelPlan: string) => {
  switch (textLabelPlan) {
    case "createService":
      return "Seleccionar el plan con el que se crearán los servicios enerBit";
    case "assignPlan":
      return "Seleccionar el plan con el que se asignarán los servicios enerBit";
    default:
      return "Seleccionar el nuevo plan";
  }
};

export const getPlanWarningLabel = (textLabelPlan: string) => {
  switch (textLabelPlan) {
    case "createService":
      return "El valor mostrado en Herramientas Tecnológicas (Fijo), no incluye IVA.";
    case "assignPlan":
      return "El valor mostrado en Herramientas Tecnológicas (Fijo), no incluye IVA.";
    default:
      return "El valor mostrado en Herramientas Tecnológicas (Fijo), no incluye IVA.";
  }
};

export const getMonthWarningLabel = (textLabelPlan: string) => {
  switch (textLabelPlan) {
    case "createService":
      return "El plan aplicará para los consumos desde el primer día del mes seleccionado.";
    case "assignPlan":
      return "El plan aplicará para los consumos desde el primer día del mes seleccionado.";
    default:
      return "El cambio de plan aplicará para los consumos desde el primer día del mes seleccionado.";
  }
};

export const getTitleConfirmModal = (
  textLabelModal: string,
  enerbitServiceAgreementPlan?: EnerbitServiceAgreement
) => {
  switch (textLabelModal) {
    case "createService":
      return `¿Está seguro de crear un servicio eB?`;
    case "assignPlan":
      return `¿Está seguro de asignar un plan a un servicio eB?`;
    default:
      return `¿Está seguro que quiere cambiar al plan ${enerbitServiceAgreementPlan?.name}?`;
  }
};
