import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { User } from "../../models/billing/billing";
import type { BillingState } from "../../models/states/BillingState";
import {
    downloadInvoiceGroup,
    getInvoices,
    getTotalOwed,
} from "../actions/billing/billing.actions";
import { IBilling, ITotalOwe } from "../../models/interfaces/IBilling";

const initialState: BillingState = {
    billing: {},
    isLoadingInvoices: false,
    billingsArray: [] as User[],
    selectedBilling: {} as User,
    totalOWed: {} as ITotalOwe,
    isLoagingTotalOwed: false,
    isLoadingDocumentGroupDownloaded: false,
    essId: "",
    yearFilter: "",
};

export const billingSlice = createSlice({
    name: "billing",
    initialState,
    reducers: {
        setSelectedBilling: (state, action: PayloadAction<User>) => {
            state.selectedBilling = action.payload;
        },
        setEssId: (state, action) => {
            state.essId = action.payload;
        },
        setYearFilter: (state, action: PayloadAction<string>) => {
            state.yearFilter = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getInvoices.pending, (state) => {
                state.isLoadingInvoices = true;
                state.billing = {};
            })
            .addCase(getInvoices.rejected.type, (state) => {
                state.isLoadingInvoices = false;
            })
            .addCase(
                getInvoices.fulfilled.type,
                (state, action: PayloadAction<IBilling>) => {
                    state.isLoadingInvoices = false;
                    state.billing = action.payload;
                },
            )
            .addCase(getTotalOwed.pending, (state) => {
                state.isLoagingTotalOwed = true;
            })
            .addCase(getTotalOwed.rejected.type, (state) => {
                state.isLoagingTotalOwed = false;
            })
            .addCase(
                getTotalOwed.fulfilled.type,
                (state, action: PayloadAction<ITotalOwe>) => {
                    state.isLoagingTotalOwed = false;
                    state.totalOWed = action.payload;
                },
            )
            .addCase(downloadInvoiceGroup.pending, (state) => {
                state.isLoadingDocumentGroupDownloaded = true;
            })
            .addCase(downloadInvoiceGroup.rejected.type, (state) => {
                state.isLoadingDocumentGroupDownloaded = false;
            })
            .addCase(
                downloadInvoiceGroup.fulfilled.type,
                (state, _action: PayloadAction<ITotalOwe>) => {
                    state.isLoadingDocumentGroupDownloaded = false;
                },
            );
    },
});
export const { setSelectedBilling, setEssId, setYearFilter } =
    billingSlice.actions;
export default billingSlice.reducer;
