import { configureStore } from "@reduxjs/toolkit";
import accountSlice from "./slices/accountSlice";
import assigneesSlice from "./slices/assigneesSlice";
import informationSlice from "./slices/informationSlice";
import planManagementSlice from "./slices/planManagementSlice";
import serviceOrdersSlice from "./slices/serviceOrdersSlice";
import usagesSlice from "./slices/usagesSlice";
import billingSlice from "./slices/billingSlice";

export const store = configureStore({
    reducer: {
        billing: billingSlice,
        information: informationSlice,
        usages: usagesSlice,
        planManagement: planManagementSlice,
        assignees: assigneesSlice,
        account: accountSlice,
        serviceOrders: serviceOrdersSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
