import { initEnviroment, onBeforeUnload } from "@enerbit/base";
import Routers from "./router/Routers";

onBeforeUnload();

initEnviroment({
  baseUrl: process.env.REACT_APP_BASE_URL,
  geopoliticsBaseUrl: process.env.REACT_APP_GEO_POLITICS_BASE_URL,
});

export default function Root() {
  return <Routers />;
}
