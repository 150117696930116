import { Box } from "@mui/material";
import { Tag } from "../../models/electricity-supply-services/ServiceInformation";
import BodyComponent from "./BodyComponent";
import TitleComponent from "./TitleComponent";

interface ShowButtonBottomProps {
  Icon?: React.ElementType;
  textButton?: string;
}

type SectionCardInformationProps = {
  background?: string;
  titleColor?: string;
  bodyColor?: string;
  titleText: string;
  data: Tag[];
  IconTitle: React.ElementType;
};

const SectionCardTasks = ({
  background,
  titleColor,
  bodyColor,
  titleText,
  data,
  IconTitle,
}: SectionCardInformationProps) => (
  <Box
    sx={{
      backgroundColor: background,
      padding: "1rem 0",
      borderRadius: "8px",
    }}
  >
    <TitleComponent
      color={titleColor}
      IconTitle={IconTitle}
      titleText={titleText}
    />
    <BodyComponent color={bodyColor} data={data} />
  </Box>
);

export default SectionCardTasks;
