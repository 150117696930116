import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import {
  AppUsers,
  Item,
  ServicesAccount,
  updatedUserServices,
} from "../../../models/accounts/ServicesAccount";

export const getServiceAccountsRelationships = createAsyncThunk<
  ServicesAccount,
  string,
  { rejectValue: AxiosError }
>(
  "getServiceAccountsRelationships",
  async (idServiceAccount: string, thunkAPI) => {
    try {
      const response: AxiosResponse<ServicesAccount> = await (
        api as AxiosInstance
      ).get(
        "accounts/service-accounts-relationships/?service_account_id=" +
          idServiceAccount +
          "&page=0&size=50"
      );
      return response.data;
    } catch (error: any) {
      console.error(error);
      const axiosError: AxiosError = error as AxiosError;
      return thunkAPI.rejectWithValue(axiosError);
    }
  }
);

export const getAppUsers = createAsyncThunk<
  updatedUserServices[],
  Item[],
  { rejectValue: AxiosError }
>("getAppUsers", async (appUsers: Item[], thunkAPI) => {
  try {
    let userServices = [...appUsers];
    const updatedUserServices: updatedUserServices[] = await Promise.all(
      userServices.map(async (user) => {
        const response: AxiosResponse<AppUsers> = await (
          api as AxiosInstance
        ).get("/users/app-users/" + user.user_id);
        const userData = response.data;
        return {
          ...user,
          rol: user.rol?.name,
          email: userData?.username,
          userName: userData?.pii?.names + " " + userData.pii?.last_names,
          legalIdCode: userData.pii?.legal_id_code,
          phone:
            userData?.pii?.phones?.length && userData?.pii?.phones[0]?.phone
              ? userData?.pii?.phones[0]?.phone
              : "",
        };
      })
    );

    return updatedUserServices;
  } catch (error: any) {
    console.error(error);
    const axiosError: AxiosError = error as AxiosError;
    return thunkAPI.rejectWithValue(axiosError);
  }
});
