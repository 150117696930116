import { Box, theme, Typography } from "@enerbit/base";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme cs

import { enqueueSnackbar } from "notistack";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EEntity } from "../../../common/enums/EServiceOrder";
import { StateStorage } from "../../../models/StateStorage";
import {
  cleanStoreServiceOrders,
  resetStatusCancelServiceOrder,
} from "../../../store/slices/serviceOrdersSlice";
import { AppDispatch } from "../../../store/store";
import { ServiceOrdersFilter } from "./ServiceOrdersFilter";
import TableServicesOrdersPage from "./Table/TableServiceOrdersPage";
export enum EOrderStatus {
  "En preparación" = "pending_assignment",
  Pendiente = "pending",
  "Parcial Completada" = "partial_done",
  Completada = "done",
  Cancelada = "cancel",
}

const entityDictionary: Record<EEntity, string> = {
  MP: "cliente",
  LD: "lead",
};

interface Props {
  entity: EEntity;
  leadId?: string | undefined | null;
}

export const ServiceOrders: FC<Props> = ({ entity, leadId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    messageErrorCancelServiceOrder,
    errorCancelServiceOrder,
    isDoneCancelServiceOrder,
  } = useSelector((state: StateStorage) => state.serviceOrders);
  const [renderKey, setRenderKey] = useState(0);
  useEffect(() => {
    return () => {
      dispatch(cleanStoreServiceOrders());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isDoneCancelServiceOrder && entity === EEntity.Client) {
      enqueueSnackbar("Orden cancelada con éxito.", {
        variant: "success",
      });
      dispatch(resetStatusCancelServiceOrder());
      setRenderKey((prevKey) => prevKey + 1);
    }
  }, [isDoneCancelServiceOrder, enqueueSnackbar, dispatch]);

  useEffect(() => {
    if (errorCancelServiceOrder && entity === EEntity.Client) {
      enqueueSnackbar(messageErrorCancelServiceOrder, { variant: "error" });
    }
    dispatch(resetStatusCancelServiceOrder());
  }, [enqueueSnackbar, errorCancelServiceOrder]);

  return (
    <>
      <Box key={renderKey}>
        <Typography
          color={theme.palette.primary.main}
          sx={{ fontSize: "1.5rem", fontWeight: "700", lineHeight: "1.5" }}
        >
          Órdenes de {entityDictionary[entity]}
          <span>&nbsp;</span>
        </Typography>
        <ServiceOrdersFilter entity={entity} leadId={leadId} />
        <Box sx={{ mt: "4rem" }}>
          <TableServicesOrdersPage entity={entity} leadId={leadId} />
        </Box>
      </Box>
    </>
  );
};

export default ServiceOrders;
