import moment, { Moment, MomentInput } from "moment-timezone";

export class MomentHelpers {
  public static convertToLastInstantOfDay(date: Moment): Moment {
    return date
      .set("hour", 23)
      .set("minute", 59)
      .set("second", 59)
      .set("millisecond", 999);
  }
  public static convertToFirstInstantOfDay(date: Moment): Moment {
    return date
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .set("millisecond", 0);
  }

  public static convertToMomentAmericaBogota(date: MomentInput): Moment {
    return moment(date).tz("America/Bogota");
  }

  public static YYYYMMDD: string = "YYYY-MM-DD";
  public static YYYYMMDDHHmm: string = "YYYY-MM-DD HH:mm";
  public static YYYYMM: string = "YYYY-MM";
  public static DMMMMYYYY: string = "D MMMM YYYY";
}
