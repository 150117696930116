// Loader.tsx
import { Box, CircularProgress, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const Loader = ({ message }: { message: string }) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      gap="1rem"
      alignItems="center"
      sx={{ margin: "1rem 0" }}
    >
      <Typography color={theme.palette.primary.main}>{message}</Typography>
      <CircularProgress />
    </Box>
  );
};

export default Loader;
