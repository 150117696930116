import {
  CloseIcon,
  enerbitColors,
  HelpOutlineOutlinedIcon,
} from "@enerbit/base";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";

interface CancelServiceOrderConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const CancelServiceOrderConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
}: CancelServiceOrderConfirmationDialogProps) => (
  <Dialog
    PaperProps={{
      style: { borderRadius: "14px", width: "100%" },
    }}
    fullWidth
    maxWidth={"xs"}
    open={open}
    onClose={onClose}
  >
    <Box sx={{ padding: "0px 10px 20px 10px" }}>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", padding: "0rem" }}>
          <Box className="Modal-content-dialog">
            <Box className="Modal-icon-button">
              <IconButton onClick={onClose}>
                <CloseIcon className="Close-icon" />
              </IconButton>
            </Box>
            <Box
              className={
                "Container-icon-modal Container-icon-modal-color-warning"
              }
              sx={{ margin: "0 0 0.8rem 0" }}
            >
              <HelpOutlineOutlinedIcon className="Info-icon" />
            </Box>
            <Typography
              fontSize="1rem"
              fontWeight={700}
              margin="0.9rem 0 0.9rem 0"
              textAlign="center"
              color={enerbitColors.primary[700]}
            >
              ¿Está seguro que quiere cancelar esta orden de servicio?
            </Typography>
            <Typography
              fontSize="0.875rem"
              fontWeight={400}
              color={enerbitColors.neutral.main}
              textAlign="center"
              width="100%"
            >
              Si lo haces, no podrás deshacer esta acción.
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: "0.6rem",
            }}
          >
            <Button
              onClick={onClose}
              size="small"
              sx={{ width: "100%", height: "3rem", marginRight: "0.5rem" }}
            >
              <Typography
                fontSize="0.875rem"
                sx={{
                  fontWeight: "bold",
                  margin: 0,
                  color: enerbitColors.neutral.main,
                }}
              >
                No, volver
              </Typography>
            </Button>

            <Button
              variant="contained"
              name="buttonModalSave"
              color="secondary"
              size="small"
              onClick={onConfirm}
              sx={{ width: "100%", height: "3rem" }}
            >
              <Typography
                fontSize="0.875rem"
                sx={{ fontWeight: "bold", margin: 0, color: "#FFFFFF" }}
              >
                Si, cancelar orden
              </Typography>
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Box>
  </Dialog>
);

export default CancelServiceOrderConfirmationDialog;
