// getEnerbitElectricitySupplyService

import { EnerbitPagination } from "@enerbit/base";
import { getServerErrorMessage } from "@enerbit/base/common/utils/serverErrorMessages";
import { AnyAction, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { Frontier } from "../../models/assignees/frontiers";
import { Leads } from "../../models/assignees/ILeads";
import { AssigneesState } from "../../models/states/AssigneesState";
import { getFrontiers, getLeads } from "../actions/assignees/assignees.actions";

const initialState: AssigneesState = {
  enerbitFrontiers: null,
  isLoadingEnerbitFrontiers: false,
  enerbitFrontiersError: null,
  leads: {
    items: [],
    page: 0,
    size: 0,
    total: 0,
    pages: 0,
  },
  isLoadingLeads: false,
  errorLeads: "",
};

export const assigneesSlice = createSlice({
  name: "assignees",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getFrontiers.pending.type, (state) => {
      state.isLoadingEnerbitFrontiers = true;
    });
    builder.addCase(
      getFrontiers.rejected.type,
      (state, { payload }: AnyAction) => {
        state.isLoadingEnerbitFrontiers = false;
        const customErrorMessages = {
          404: "Error, no se encontraron fronteras",
          403: "Usuario no tiene permisos",
          405: "Error del cliente",
          500: "Error del servidor. No fue posible obtener las fronteras.",
        };
        state.enerbitFrontiersError = getServerErrorMessage(
          payload.status,
          customErrorMessages
        );
      }
    );
    builder
      .addCase(
        getFrontiers.fulfilled.type,
        (state, action: PayloadAction<EnerbitPagination<Frontier>>) => {
          state.isLoadingEnerbitFrontiers = false;
          state.enerbitFrontiers = action.payload;
        }
      )
      .addCase(getLeads.pending, (state) => {
        state.isLoadingLeads = true;
      })
      .addCase(
        getLeads.fulfilled,
        (state, action: PayloadAction<EnerbitPagination<Leads>>) => {
          state.isLoadingLeads = false;
          state.leads = action.payload;
        }
      )
      .addCase(
        getLeads.rejected.type,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoadingLeads = false;
          state.errorLeads =
            "Ha ocurrido un error inesperado, por favor inténtelo de nuevo.";
        }
      );
  },
});

export default assigneesSlice.reducer;
export const {} = assigneesSlice.actions;
