export type Period = "Hora" | "Mes" | "Día" | "Semana";
export type PeriodSTR = "hour" | "month_start" | "day" | "week";

export const getPeriodStr = (period: Period): PeriodSTR => {
  switch (period) {
    case "Hora":
      return "hour";
    case "Mes":
      return "month_start";
    case "Día":
      return "day";
    case "Semana":
      return "week";
  }
};

export const periodsTableToSelect: Period[] = ["Mes", "Día", "Hora"];
export const periodsCompareToSelect: Period[] = ["Mes", "Semana", "Día"];
