import {
  Badge,
  Box,
  Button,
  CalendarTodayOutlinedIcon,
  CloseIcon,
  ContentPasteSearchOutlinedIcon,
  enerbitColors,
  formatDate,
  Grid,
  Menu,
  MenuItem,
  OutlinedInput,
  RemoveRedEyeOutlinedIcon,
  Select,
  SelectChangeEvent,
  Typography,
} from "@enerbit/base";
import { useEffect, useState, type FC } from "react";
import { DateRange, RangeKeyDict } from "react-date-range";
import { useDispatch, useSelector } from "react-redux";
import { EEntity } from "../../../common/enums/EServiceOrder";
import { StyledChip } from "../../../common/style/StyledChip";
import { StateStorage } from "../../../models/StateStorage";
import {
  getServiceOrderTypes,
  getServicesOrders,
} from "../../../store/actions/service-orders/service-orders.actions";
import { AppDispatch } from "../../../store/store";

export enum EOrderStatus {
  "En preparación" = "pending_assignment",
  Pendiente = "pending",
  "Parcial Completada" = "partial_done",
  Completada = "done",
  Cancelada = "cancel",
}

interface Props {
  entity: EEntity;
  leadId?: string | undefined | null;
}

interface Filters {
  since: string;
  until: string;
  serviceOrdersTypeName?: string;
  serviceStatus?: string;
}

export const ServiceOrdersFilter: FC<Props> = ({ entity, leadId }) => {
  const dispatch = useDispatch<AppDispatch>();

  const { listServiceOrderTypes } = useSelector(
    (state: StateStorage) => state.serviceOrders
  );
  const { service } = useSelector((state: StateStorage) => state.information);

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [dateRange, setDateRange] = useState<{
    startDate: string | Date;
    endDate: string | Date;
  }>({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [hasDateRange, setHasDateRange] = useState(false);

  const [filters, setFilters] = useState({
    since: "",
    until: "",
    serviceOrdersTypeName: "",
    serviceStatus: "",
  });

  const [dateAnchorElement, setDateAnchorElement] =
    useState<null | HTMLElement>(null);

  useEffect(() => {
    dispatch(getServiceOrderTypes());
  }, [dispatch]);

  useEffect(() => {
    if (!isFirstRender && service?.measurement_point_id) {
      const id =
        entity === EEntity.Client
          ? service?.measurement_point_id
          : leadId ?? "";
      dispatch(
        getServicesOrders({
          id: id,
          entity,
          dateRange: {
            startDate: filters.since,
            endDate: filters.until,
          },
          status: filters.serviceStatus,
          orderTypeId: filters.serviceOrdersTypeName,
        })
      );
    } else {
      setIsFirstRender(false);
    }
  }, [filters, dispatch, service?.measurement_point_id, entity]);

  const changeParams = (newParams: Partial<Filters>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newParams,
    }));
  };

  const handleSelect = (ranges: RangeKeyDict) => {
    const { selection } = ranges;
    setDateRange({
      startDate: selection.startDate ? selection.startDate : new Date(),
      endDate: selection.endDate ? selection.endDate : new Date(),
    });
    setHasDateRange(!!selection.startDate && !!selection.endDate);
    changeParams({
      since: selection.startDate ? formatDate(selection.startDate) : "",
      until: selection.endDate ? formatDate(selection.endDate) : "",
    });
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setDateAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setDateAnchorElement(null);
  };

  const cleanFilters = () => {
    setFilters({
      serviceOrdersTypeName: "",
      serviceStatus: "",
      since: "",
      until: "",
    });
    setDateRange({ startDate: "", endDate: "" });
    setHasDateRange(false);
  };

  const serviceOrderTypeNames = listServiceOrderTypes.reduce(
    (serviceOrderTypeMap: Record<string, string>, item) => {
      serviceOrderTypeMap[item.id] = item.name;
      return serviceOrderTypeMap;
    },
    {}
  );

  return (
    <>
      <form>
        <Grid sx={{ flexGrow: 1, marginTop: "1.5rem" }} container>
          <Grid item xs={12} md={3} lg={3}>
            <Box sx={{ mb: "10px", color: enerbitColors.primary[700] }}>
              Tipo de orden
            </Box>
            <Box className="TextField-without-border-radius Text-select-field">
              <Select
                fullWidth
                value={filters.serviceOrdersTypeName || ""}
                onChange={(event: SelectChangeEvent<string | null>) =>
                  changeParams({
                    serviceOrdersTypeName: event.target.value ?? undefined,
                  })
                }
                displayEmpty
                renderValue={(selected) => {
                  if (!selected) {
                    return (
                      <span style={{ color: "gray" }}>
                        Busca por un tipo de orden
                      </span>
                    );
                  }
                  return serviceOrderTypeNames[selected as string];
                }}
                input={
                  <OutlinedInput
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        color: filters.serviceOrdersTypeName ? "black" : "gray",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: `${enerbitColors.primary[300]}`,
                        borderRadius: "0.8rem",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: `${enerbitColors.primary[300]}`,
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: `${enerbitColors.primary[300]}`,
                      },
                    }}
                  />
                }
              >
                {Object.keys(serviceOrderTypeNames).map((key) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {serviceOrderTypeNames[key]}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            lg={3}
            sx={{
              pl: "10px",
            }}
          >
            <Box sx={{ mb: "10px", color: enerbitColors.primary[700] }}>
              Estado
            </Box>
            <Select
              fullWidth
              value={filters.serviceStatus || ""}
              onChange={(event: SelectChangeEvent<string | null>) =>
                changeParams({ serviceStatus: event.target.value ?? "" })
              }
              displayEmpty
              renderValue={(selected) => {
                if (!selected) {
                  return (
                    <span style={{ color: "gray" }}>Busca por estado</span>
                  );
                }
                return Object.keys(EOrderStatus).find(
                  (key) =>
                    EOrderStatus[key as keyof typeof EOrderStatus] === selected
                );
              }}
              input={
                <OutlinedInput
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${enerbitColors.primary[300]}`,
                      borderRadius: "0.8rem",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${enerbitColors.primary[300]}`,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${enerbitColors.primary[300]}`,
                    },
                  }}
                />
              }
            >
              {Object.keys(EOrderStatus).map((key) => {
                return (
                  <MenuItem
                    key={key}
                    value={EOrderStatus[key as keyof typeof EOrderStatus]}
                  >
                    {key}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item xs={12} md={4} lg={4} sx={{ pl: "10px" }}>
            <Box sx={{ mb: "10px", color: enerbitColors.primary[700] }}>
              Fecha
            </Box>
            <Box
              className="TextField-without-border-radius Text-select-field"
              sx={{ height: "100%", marginTop: "0 !important" }}
            >
              <Badge
                badgeContent={""}
                className={hasDateRange ? "ping" : ""}
                color="primary"
                variant="dot"
                invisible={!hasDateRange}
                sx={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <span className={hasDateRange ? "ping-animation" : ""}></span>
                <Button
                  id="dynamic-date"
                  fullWidth
                  disabled={false}
                  size="large"
                  sx={{
                    border: `1px solid ${enerbitColors.primary[300]}`,
                    width: "100%",
                    height: "53.11px",
                    whiteSpace: "wrap",
                    justifyContent: "space-between",
                    color: hasDateRange
                      ? `${enerbitColors.neutral[900]} !important`
                      : `${enerbitColors.neutral[400]} !important`,
                    fontWeight: "400 !important",
                  }}
                  endIcon={<CalendarTodayOutlinedIcon />}
                  onClick={handleOpen}
                >
                  {hasDateRange
                    ? `De: ${formatDate(
                        dateRange.startDate
                      )} - Hasta: ${formatDate(dateRange.endDate)}`
                    : "De - Hasta"}
                </Button>
              </Badge>
              <Menu
                anchorEl={dateAnchorElement}
                open={Boolean(dateAnchorElement)}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                MenuListProps={{ "aria-labelledby": "basic-button" }}
                slotProps={{
                  paper: {
                    style: {
                      minWidth: dateAnchorElement
                        ? dateAnchorElement.clientWidth
                        : "100%",
                    },
                  },
                }}
              >
                <DateRange
                  showDateDisplay={false}
                  editableDateInputs={true}
                  ranges={[
                    {
                      startDate: dateRange.startDate
                        ? new Date(dateRange.startDate)
                        : undefined,
                      endDate: dateRange.endDate
                        ? new Date(dateRange.endDate)
                        : undefined,
                      key: "selection",
                    },
                  ]}
                  onChange={handleSelect}
                  moveRangeOnFirstSelection={false}
                  retainEndDateOnFirstSelection
                  rangeColors={[enerbitColors.primary.main]}
                />
              </Menu>
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                mt: "10px !important",
                pt: "12px",
                pl: "10px",
                height: "100%",
              }}
            >
              <Button
                sx={{
                  height: "3.5rem",
                  width: "100%",
                  padding: "10px 6px 10px 6px !important",
                  minWidth: "100px",
                }}
                color="primary"
                size="small"
                onClick={() => {
                  cleanFilters();
                }}
              >
                <Box sx={{ color: enerbitColors.primary[700] }}>
                  Limpiar filtro
                </Box>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
      <Grid container item spacing={3} sx={{ mt: "0.9rem" }}>
        <Grid item container xs={12} spacing={3}>
          <Grid item display={"flex"} alignItems={"center"}>
            <Typography component={"span"} sx={{ fontWeight: "bold" }}>
              Acciones:
            </Typography>
          </Grid>
          <Grid item>
            <StyledChip
              icon={<CloseIcon fontSize="small" />}
              label="Cancelar orden de servicio"
              sx={{ background: "#FEECEB", color: "#F04438" }}
            />
          </Grid>
          <Grid item>
            <StyledChip
              icon={<RemoveRedEyeOutlinedIcon fontSize="small" />}
              label="Detalle de la orden"
              sx={{ background: "#EFE9FB", color: "#53358E" }}
            />
          </Grid>
          <Grid item>
            <StyledChip
              icon={<ContentPasteSearchOutlinedIcon fontSize="small" />}
              label="Ver acta"
              sx={{ background: "#EDF4FF", color: "#3B82F6" }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
