export const changeActivePropertyName = (sui_code: string) => {
  switch (sui_code) {
    case "0":
      return "Propiedad del usuario";
    case "50":
      return "Propiedad Compartida";
    case "100":
      return "Propiedad del OR";
    case "101":
      return "No aplica. Nivel de tensión diferente a 1";
    default:
      return "Sui_code no encontrado";
  }
};
