import { CustomAlert, NotiProvider } from "@enerbit/base";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EEntity } from "../../common/enums/EServiceOrder";
import TableSkeleton from "../../helpers/Loader/Skeleton/Orders/Table/TableSkeleton";
import { StateStorage } from "../../models/StateStorage";
import { getLeads } from "../../store/actions/assignees/assignees.actions";
import { AppDispatch } from "../../store/store";
import { ServiceOrders } from "./components/ServiceOrders";

export const ServiceOrdersContainer = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { entity } = useSelector((state: StateStorage) => state.serviceOrders);
  const { leads, isLoadingLeads, errorLeads } = useSelector(
    (state: StateStorage) => state.assignees
  );

  const { service } = useSelector((state: StateStorage) => state.information);

  useEffect(() => {
    if (service && service.measurement_point_id) {
      dispatch(getLeads(service.measurement_point_id));
    }
  }, [dispatch]);

  let leadId: string | null | undefined = null;
  if (service && service.measurement_point_id) {
    const lead = leads?.items?.find(
      (lead) => lead.measurement_point_id === service.measurement_point_id
    );
    leadId = lead ? lead.id : null;
  }

  return (
    <>
      <NotiProvider>
        {entity.client === EEntity.Client && (
          <>
            <ServiceOrders entity={entity.client} />
          </>
        )}
        {entity.lead === EEntity.Lead && (
          <>
            <Box sx={{ marginTop: "2.5rem" }}>
              {isLoadingLeads ? (
                <Box sx={{ margin: "6rem 0" }}>
                  <TableSkeleton />
                </Box>
              ) : (
                <ServiceOrders entity={entity.lead} leadId={leadId} />
              )}
              {errorLeads && (
                <Box sx={{ mt: 2 }}>
                  <CustomAlert
                    severity="error"
                    text={errorLeads}
                    onClose={() => null}
                  />
                </Box>
              )}
            </Box>
          </>
        )}
      </NotiProvider>
    </>
  );
};
export default ServiceOrdersContainer;
