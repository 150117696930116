import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  HighlightOffOutlinedIcon,
  styled,
  theme,
} from "@enerbit/base";
import { useDispatch, useSelector } from "react-redux";
import { StateStorage } from "../../../models/StateStorage";
import {
  postReconnectService,
  setOpenConfirmReconnectDialog,
} from "../../../store/slices/informationSlice";
import { AppDispatch } from "../../../store/store";

const ReconnectServiceDialog = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { openConfirmReconnectDialog, service } = useSelector(
    (state: StateStorage) => state.information
  );

  return (
    <div>
      <Dialog
        open={openConfirmReconnectDialog}
        onClose={() => dispatch(setOpenConfirmReconnectDialog(false))}
        fullWidth={true}
        PaperProps={{
          sx: { p: "10px 20px", borderRadius: "20px", maxWidth: "650px" },
        }}
      >
        <DialogActions sx={{ pb: 0 }}>
          <Button
            onClick={() => dispatch(setOpenConfirmReconnectDialog(false))}
            sx={{
              "& .MuiButtonBase-root": {
                padding: "5rem",
                borderRadius: "100%",
              },
            }}
          >
            <HighlightOffOutlinedIcon fontSize="large" />
          </Button>
        </DialogActions>
        <DialogTitle
          color={theme.palette.primary.main}
          sx={{ fontSize: "24px", pt: 0, my: "20px" }}
        >
          ¿Estás seguro que deseas reconectar este servicio?
        </DialogTitle>
        <ButtonsContainer>
          <Button
            sx={{ width: "100%" }}
            color="primary"
            onClick={() => {
              dispatch(setOpenConfirmReconnectDialog(false));
            }}
          >
            Volver atrás
          </Button>
          <Button
            sx={{ width: "100%" }}
            variant="contained"
            color="secondary"
            onClick={() => {
              dispatch(postReconnectService(service!.meter?.id));
              dispatch(setOpenConfirmReconnectDialog(false));
            }}
          >
            Estoy seguro
          </Button>
        </ButtonsContainer>
      </Dialog>
    </div>
  );
};

export default ReconnectServiceDialog;

const ButtonsContainer = styled(Box)`
  display: flex;
  gap: 1rem;
  margin-bottom: 20px;
  margin-top: 20px;
`;
