import { enerbitColors } from "@enerbit/base";
import { Box, Typography } from "@mui/material";

const TitleComponent = ({
  color = `${enerbitColors.neutral[700]}`,
  titleText,
  IconTitle,
}: {
  color?: string;
  titleText: string;
  IconTitle: React.ElementType;
  showButtonTop?: boolean;
}) => (
  <Box sx={{ flexGrow: 1, margin: "0 0.5rem 0.8rem 0" }}>
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Typography sx={{ display: "flex", alignItems: "center" }}>
        <IconTitle sx={{ color: color, fontSize: "17px" }} />{" "}
        <span
          style={{
            color: color,
            fontWeight: "bold",
            marginLeft: "7px",
            fontSize: "1rem",
          }}
        >
          {titleText}
        </span>
      </Typography>
    </Box>
    <Box
      sx={{
        borderBottom: `0.8px solid ${color}`,
        margin: "0.5rem 0",
      }}
    ></Box>
  </Box>
);

export default TitleComponent;
