import type {
    ElectricitySupplyService,
    EnerbitPagination,
    Estate,
} from "@enerbit/base";
import * as singleSpa from "single-spa";
import type { Frontier } from "../../models/assignees/frontiers";
import type { Service } from "../../models/electricity-supply-services/ServiceInformation";

const mountedAppSingleSpaaccount = (
    idServiceAccount: string,
    shouldShowNewUser: boolean,
    showDetail: boolean,
    onClickDetail?: (user_id: string) => void,
) => {
    singleSpa.registerApplication(
        "app-access-management",
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        (): Promise<any> =>
            System.import("@enerBit/access-management").catch((_e) =>
                console.log("error"),
            ),
        (location) => location.pathname.startsWith("/"),
        {
            idServiceAccount: idServiceAccount,
            shouldShowNewUser: shouldShowNewUser,
            showDetail: showDetail,
            onClickDetail: onClickDetail,
        },
    );
};

export const mountedDisassembleaccount = async (
    idServiceAccount: string,
    shouldShowNewUser: boolean,
    showDetail: boolean,
    onClickDetail?: (user_id: string) => void,
) => {
    const appNamesAccess = singleSpa
        .getAppNames()
        .find((item) => item === "app-access-management");

    if (appNamesAccess) {
        singleSpa
            .unregisterApplication("app-access-management")
            .then(() =>
                mountedAppSingleSpaaccount(
                    idServiceAccount,
                    shouldShowNewUser,
                    showDetail,
                    onClickDetail,
                ),
            );
    } else {
        mountedAppSingleSpaaccount(
            idServiceAccount,
            shouldShowNewUser,
            showDetail,
            onClickDetail,
        );
    }
};

export interface ParamsServiceCreateMicro {
    leadUserEmail?: string;
    serviceAccountId: string;
    userId: string;
    onCloseModal: (electricitySupplyService: ElectricitySupplyService) => void;
    service: Service;
    estate: Estate | null;
    frontiers?: EnerbitPagination<Frontier> | null;
}

const mountedAppSingleSpaElectricityService = (
    params: ParamsServiceCreateMicro,
) => {
    singleSpa.registerApplication(
        "app-electricity-supply-service-create",
        (): Promise<singleSpa.LifeCycles> =>
            System.import("@enerBit/electricity-supply-service-create"),
        (location) => location.pathname.startsWith("/"),
        params,
    );
};

export const mountedApps = async (params: ParamsServiceCreateMicro) => {
    const appNamesAccess = singleSpa
        .getAppNames()
        .find((item) => item === "app-electricity-supply-service-create");

    if (appNamesAccess) {
        singleSpa
            .unregisterApplication("app-electricity-supply-service-create")
            .then(async () => {
                await mountedAppSingleSpaElectricityService(params);
            });
    } else {
        await mountedAppSingleSpaElectricityService(params);
    }
};

const mountedAppSingleSpaEssBillingDetail = (
    serviceTypeName: string,
    invoiceId: string,
) => {
    singleSpa.registerApplication(
        "app-ess-billing-detail",
        (): Promise<singleSpa.LifeCycles> =>
            System.import("@enerBit/ess-billing-detail"),
        (location) => location.pathname.startsWith("/"),
        { serviceTypeName, invoiceId },
    );
};

export const mountedDisassembleAppsEssBillingDetail = async (
    serviceTypeName: string,
    invoiceId: string,
) => {
    const appNamesAccess = singleSpa
        .getAppNames()
        .find((item) => item == "app-ess-billing-detail");

    if (appNamesAccess) {
        singleSpa
            .unregisterApplication("app-ess-billing-detail")
            .then(async () => {
                await mountedAppSingleSpaEssBillingDetail(
                    serviceTypeName,
                    invoiceId,
                );
            });
    } else {
        await mountedAppSingleSpaEssBillingDetail(serviceTypeName, invoiceId);
    }
};

const mountedAppSingleSpaDetailMemos = ({
    serviceTypeName,
    invoiceMemoId,
}: {
    serviceTypeName: string;
    invoiceMemoId: string;
}) => {
    singleSpa.registerApplication(
        "app-detail-memos",
        (): Promise<singleSpa.LifeCycles> =>
            System.import("@enerBit/detail-memos"),
        (location) => location.pathname.startsWith("/"),
        { serviceTypeName, invoiceMemoId },
    );
};

export const mountedDisassembleAppsDetailMemos = async ({
    service_type_name,
    invoice_memo_id,
}: {
    service_type_name: string;
    invoice_memo_id: string;
}) => {
    const appNamesAccess = singleSpa
        .getAppNames()
        .find((item) => item == "app-detail-memos");

    if (appNamesAccess) {
        singleSpa.unregisterApplication("app-detail-memos").then(async () => {
            await mountedAppSingleSpaDetailMemos({
                serviceTypeName: service_type_name,
                invoiceMemoId: invoice_memo_id,
            });
        });
    } else {
        await mountedAppSingleSpaDetailMemos({
            serviceTypeName: service_type_name,
            invoiceMemoId: invoice_memo_id,
        });
    }
};
