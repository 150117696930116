import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import {
  AppUsers,
  ServicesAccount,
  setCurrentUserPayload,
  setSelectedUserPayload,
} from "../../models/accounts/ServicesAccount";
import { AccountState } from "../../models/states/AccountsState";
import {
  getAppUsers,
  getServiceAccountsRelationships,
} from "../actions/account/account.actions";

const initialState: AccountState = {
  usersServiceAccount: {},
  hasErrorUsersServiceAccount: "",
  isLoadingUsersServiceAccount: false,
  appUsers: [],
  hasErrorAppUsers: "",
  isLoadingAppUsers: false,
  appUser: null,
  selectedAppUser: [],
};

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<setCurrentUserPayload>) => {
      state.appUser = action.payload.appUsers;
    },
    setSelectedUser: (state, action: PayloadAction<setSelectedUserPayload>) => {
      state.selectedAppUser = [
        {
          user_id: action.payload?.user.user_id,
          rol: action.payload?.user.rol,
          legalIdCode: action.payload?.user.legalIdCode,
          phone: action.payload?.user.phone,
          email: action.payload?.user.email,
          userName: action.payload?.user.userName,
          service_account_id: action.payload?.user.service_account_id,
        },
      ];
    },

    cleanStoreAccount: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServiceAccountsRelationships.pending.type, (state) => {
        state.isLoadingUsersServiceAccount = true;
      })
      .addCase(
        getServiceAccountsRelationships.fulfilled.type,
        (state, action: PayloadAction<ServicesAccount>) => {
          state.usersServiceAccount = action.payload;
          state.isLoadingUsersServiceAccount = false;
        }
      )
      .addCase(
        getServiceAccountsRelationships.rejected.type,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoadingUsersServiceAccount = false;
          if (action.payload?.response?.status === 404) {
            state.hasErrorAppUsers = "No existe relacion de cuenta";
          } else {
            state.hasErrorAppUsers = "Error del servidor";
          }
        }
      )
      .addCase(getAppUsers.pending.type, (state) => {
        state.isLoadingAppUsers = true;
        state.hasErrorAppUsers = "";
      })
      .addCase(
        getAppUsers.fulfilled.type,
        (state, action: PayloadAction<AppUsers>) => {
          state.appUsers = action.payload;
          state.isLoadingAppUsers = false;
        }
      )
      .addCase(
        getAppUsers.rejected.type,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoadingAppUsers = false;
          if (action.payload?.response?.status === 404) {
            state.hasErrorAppUsers =
              "No existen usuarios asociados a la cuenta";
          } else {
            state.hasErrorAppUsers = "Error del servidor";
          }
        }
      );
  },
});

export const { setCurrentUser, setSelectedUser, cleanStoreAccount } =
  accountSlice.actions;

export default accountSlice.reducer;
