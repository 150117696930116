import {
  Box,
  Button,
  ErrorOutlineOutlinedIcon,
  FlashOnOutlinedIcon,
  Typography,
  WarningIcon,
  enerbitColors,
  styled,
  theme,
} from "@enerbit/base";
import { useDispatch, useSelector } from "react-redux";
import TitleComponent from "../../../../helpers/CardInformationGeneral/TitleComponent";
import { ButtonConnectionProps } from "../../../../models/props/ButtonConnectionProps";
import { ConnectionStatusCardProps } from "../../../../models/props/ConnectionStatusCard";
import { StateStorage } from "../../../../models/StateStorage";
import { setOpenConfirmReconnectDialog } from "../../../../store/slices/informationSlice";
import { AppDispatch } from "../../../../store/store";

export const ConnectionCardActive = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { service } = useSelector((state: StateStorage) => state.information);
  return (
    <ConnectionStatusCard status={enerbitColors?.success.main}>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <TitleComponent
          color={theme.palette.primary.main}
          IconTitle={WarningIcon}
          titleText={"Estado del servicio"}
          showButtonTop={false}
        />

        <>
          {" "}
          <ButtonConnection
            bgcolor={`${enerbitColors?.success[200]} !important`}
            colortext={`${enerbitColors?.success.main} !important`}
            size="small"
            variant="contained"
            fullWidth
            sx={{ m: "0.1rem 0 0.5rem 0", width: "30%" }}
            onClick={() => dispatch(setOpenConfirmReconnectDialog(true))}
            disabled={true}
          >
            <FlashOnOutlinedIcon
              sx={{
                color: `${enerbitColors?.success.main} !important`,
                fontSize: "20px",
                fontWeight: "bold",
                marginRight: "0.3rem",
              }}
            />{" "}
            Activo
          </ButtonConnection>
        </>
      </Box>

      <>
        {" "}
        <Box
          sx={{
            display: "flex",
            width: "100%",
            marginTop: "0.1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <span
              style={{
                color: enerbitColors?.neutral[700],
                fontWeight: "bold",
                marginLeft: "2px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <ErrorOutlineOutlinedIcon
                sx={{
                  color: enerbitColors?.neutral[700],
                  fontSize: "10px",
                  marginRight: "0.2rem",
                }}
              />{" "}
              ¿Es desconectable?:
            </span>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <span
                style={{
                  color: enerbitColors?.neutral[700],
                  fontWeight: "400",
                  marginLeft: "0.3rem",
                }}
              >
                {service?.cuttable ? "Sí" : "No"}
              </span>
            </Typography>
          </Box>
        </Box>
      </>
    </ConnectionStatusCard>
  );
};

const ConnectionStatusCard = styled(Box)(
  ({ status }: ConnectionStatusCardProps) => ({
    backgroundColor: `#FFFFFF`,
    border: `1px solid ${status}`,
    padding: "12px 16px 12px 12px",
    margin: "20px 0px 20px",
    borderRadius: "10px",
    position: "relative",
  })
);

const ButtonConnection = styled(Button)(
  ({ bgcolor, colortext }: ButtonConnectionProps) => ({
    borderRadius: "6px !important",
    fontWeight: "normal !important",
    backgroundColor: `${bgcolor}`,
    color: `${colortext}`,
    boxShadow: "unset !important",
    width: "100%",
    height: "1.9rem",
    fontSize: "0.875rem",
    //Hover
    "&:hover": {
      backgroundColor: `${bgcolor}`,
    },
    "&:disabled": {
      backgroundColor: `${bgcolor}`,
    },
  })
);
