import React from "react";
import ReactDOMClient from "react-dom/client";
import singleSpaReact from "single-spa-react";

import { initEnviroment } from "@enerbit/base";
import Root from "./root.component";
import "./styles/attention.scss";

initEnviroment({
  baseUrl: process.env.REACT_APP_BASE_URL,
});

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: Root,
  renderType: "createRoot",
});

export const { bootstrap, mount, unmount } = lifecycles;
